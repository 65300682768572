import { Injectable } from '@angular/core';
import { Theme, fitzdares, talksport, dreamteam, defaultTheme, rockthegoat, racingpost, oddschecker, bolavip, whoscored, planetsport, greatgoals, attheraces, nationalworld, fitzdaressportsbook } from "./theme";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private active: Theme = defaultTheme;
  private availableThemes: Theme[] = [defaultTheme, fitzdares, talksport, dreamteam, rockthegoat, racingpost, oddschecker, bolavip, whoscored,  planetsport, greatgoals, attheraces, nationalworld, fitzdaressportsbook];

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDefaultTheme(): boolean {
    return this.active.name === defaultTheme.name
  }

  isTalksportTheme(): boolean {
    return this.active.name === talksport.name;
  }

  isFitzdaresTheme(): boolean {
    return this.active.name === fitzdares.name;
  }

  isDreamteamTheme(): boolean {
    return this.active.name === dreamteam.name
  }

  isRTGTheme(): boolean {
    return this.active.name === rockthegoat.name
  }

  isRacingpostTheme() : boolean {
    return this.active.name === racingpost.name
  }

  isOddscheckerTheme() : boolean {
    return this.active.name === oddschecker.name
  }

  isBolavipTheme() : boolean {
    return this.active.name === bolavip.name
  }

  isWhoScoredTheme(): boolean {
    return this.active.name === whoscored.name
  }

  isPlanetSportTheme(): boolean {
    return this.active.name === planetsport.name
  }

  isGreatGoalsTheme(): boolean {
    return this.active.name === greatgoals.name
  }

  isAtTheRacesTheme(): boolean {
    return this.active.name === attheraces.name
  }

  isNationalWorldTheme(): boolean {
    return this.active.name === nationalworld.name
  }

  isFitzdaresSportsBookTheme(): boolean {
    return this.active.name === fitzdaressportsbook.name
  }


  setDefaultTheme(): void {
    this.setActiveTheme(defaultTheme)
  }

  setTalksportTheme(): void {
    this.setActiveTheme(talksport);
  }

  setFitzdaresTheme(): void {
    this.setActiveTheme(fitzdares);
  }

  setDreamteamTheme(): void {
    this.setActiveTheme(dreamteam)
  }

  setRTGTheme(): void {
    this.setActiveTheme(rockthegoat)
  }

  setRacingpostTheme(): void {
    this.setActiveTheme(racingpost)
  }

  setOddscheckerTheme(): void {
    this.setActiveTheme(oddschecker)
  }

  setBolavipTheme(): void {
    this.setActiveTheme(bolavip)
  }

  setWhoScoredTheme(): void {
    this.setActiveTheme(whoscored)
  }

  setPlanetSportTheme(): void  {
    this.setActiveTheme(planetsport);
  }

  setGreatGoalsTheme(): void {
    this.setActiveTheme(greatgoals);
  }

  setAtTheRacesTheme(): void {
    this.setActiveTheme(attheraces);
  }

  setNationalWorldTheme(): void {
    this.setActiveTheme(nationalworld);
  }

  setAlphaTheme(): void {
    this.setActiveTheme(rockthegoat);
  }

  setFitzdaresSportsBookTheme (): void {
    this.setActiveTheme(fitzdaressportsbook);
  }

  setActiveThemeByName(themeName: string): void {
    if (themeName == 'fitzdares') {
      this.setActiveTheme(fitzdares);
    } else if (themeName == 'talksport') {
      this.setActiveTheme(talksport);
    } else if (themeName == 'dreamteam') {
      this.setActiveTheme(dreamteam)
    } else if (themeName === 'rockthegoat') {
      this.setActiveTheme(rockthegoat)
    } else if (themeName === 'racingpost') {
      this.setActiveTheme(racingpost)
    } else if (themeName === 'oddschecker') {
      this.setActiveTheme(oddschecker)
    } else if (themeName === 'bolavip') {
      this.setActiveTheme(bolavip)
    } else if (themeName === 'whoscored') {
      this.setActiveTheme(whoscored)
    } else if (themeName === 'planetsport') {
      this.setActiveTheme(planetsport)
    } else if (themeName === 'greatgoals') {
      this.setActiveTheme(greatgoals)
    } else if (themeName === 'attheraces') {
      this.setActiveTheme(attheraces)
    } else if (themeName === 'nationalworld') {
      this.setActiveTheme(nationalworld)
    } else if (themeName === 'fitzdaressportsbook') {
      this.setActiveTheme(fitzdaressportsbook)
    } else if (themeName === 'alpha') {
      this.setActiveTheme(rockthegoat)
    } else {
      this.setActiveTheme(defaultTheme)
    }
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}
