<!-- Dropdown multi-select -->
<div class="input_menu_container">
    <div class="selected_items_list" *ngIf="selectMultiple">  
        <div class="selected_item" *ngFor="let selected of selectedOptions">
            <span class="selected_item_label">{{selected[displayKey]}}</span> 
            <div class="remove_icon" (click)="selectOption(selected)">
                <span class="icon ng-icon-times-circle"></span>
            </div>
        </div>
        <span (click)="showDropdown = !showDropdown" class="toggle_menu_icon">
            <span class="icon ng-icon-chevron-down"></span>
        </span>
    </div>
    <div class="select_input_container" (click)="showDropdown = !showDropdown" *ngIf="!selectMultiple">
        <input class="select_input dropdown_select_input" (keydown)="$event.preventDefault()" type="text" [value]="selectedOptions.length ? selectedOptions[0][displayKey] : ''">
        <div class="right_icon">
            <span class="icon ng-icon-chevron-down"></span>
        </div>
    </div>
    <div class="dropdown_menu_container" *ngIf="showDropdown">
        <ul class="menu_items">
            <div class="select_input_container filter_">
                <div class="left_icon">
                    <span class="icon ng-icon-search"></span>
                </div>
                <input class="select_input filter_input" type="text"  [(ngModel)]="optionsFilterString">
                <div class="right_icon"  *ngIf="optionsFilterString.length" (click)="optionsFilterString = ''">
                    <span class="icon ng-icon-times-circle"></span>
                </div>
            </div>
            <li class="menu_item" *ngFor="let option of options| filterOptions : displayKey : optionsFilterString" (click)="selectOption(option)">
                <span class="list_item">{{option[displayKey]}}</span>
                <span class="check_icon">
                    <span class="icon ng-icon-check" *ngIf="option.checked"></span>
                </span>
            </li>
        </ul>
    </div>
</div>
<!-- #Dropdown multi-select -->