import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UtilsService } from "projects/fantasy-shared/src/services";

@Component({
    selector: 'app-nudge',
    templateUrl: './nudge.component.html',
    styleUrls: ['./nudge.component.scss']
})

export class NudgeComponent implements OnInit {

    @Input() nudgeValue: number = 0;
    @Input() overBoxNumber: number;
    @Input() startingOver: number;
    @Input() nudgeTitle: string;
    @Output() nudgeValueEmit = new EventEmitter<number>();
    @Output() closeNudgePopup = new EventEmitter<boolean>();


    constructor(private utilService: UtilsService) {
    }

    ngOnInit() {
    }

    getNudgeValue() {
        return this.nudgeValue;
    }

    addNudgeValue() {

        if (this.nudgeValue >= 36) {
            this.nudgeValue = 36;
            return;
        }

        this.nudgeValue++;
    }

    subtractNudgeValue() {

        if (this.nudgeValue < 1) {
            this.nudgeValue = 0;
            return;
        }

        this.nudgeValue--;
    }

    /**
      * Confirm the nudge value, emit the value to the parent component
      * and close the nudge modal
     */
    confirmNudgeValue() {
        this.nudgeValueEmit.emit(this.nudgeValue);
    }

    cancel(){
        this.nudgeValue = 0;
        this.closeNudgePopup.emit(true);
    }

    getSuffix() {
        return this.utilService.getOrdinalSuffix(this.startingOver + this.overBoxNumber);
    }

}