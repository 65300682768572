import { Injectable } from '@angular/core';

/**
 *  Provide Local Storage Interfaces for consistency
 */

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly ACCESS_TOKEN_EXPIRY = 'ACCESS_TOKEN_EXPIRY';
  private readonly REFRESH_TOKEN_EXPIRY = 'REFRESH_TOKEN_EXPIRY';
  private readonly ROLE_ID = 'ROLE_ID';
  private readonly PLAYER_ID = 'PLAYER_ID';
  private readonly DISPLAY_NAME = 'DISPLAY_NAME';
  private readonly PLAYER_NAME = 'PLAYER_NAME';
  private readonly PWD_RESETID = 'PWD_RESETID';
  private readonly JOURNEY_COMPLETE = 'JOURNEY_COMPLETE';
  private readonly JOURNEY_VERSION = 'JOURNEY_VERSION';
  private readonly DEPOSIT_CONSENT = 'DEPOSIT_CONSENT';
  private readonly FSB_ACCESS_TOKEN = 'FSB_ACCESS_TOKEN';

  storeTokensAtLogin(tokenData) {
    localStorage.setItem(this.ROLE_ID, tokenData['playerDetails']['roleId']);
    localStorage.setItem(this.PLAYER_ID, tokenData['playerDetails']['playerId']);
    localStorage.setItem(this.ACCESS_TOKEN_EXPIRY, tokenData['accessTokenExpiry']);
    localStorage.setItem(this.REFRESH_TOKEN_EXPIRY, tokenData['refreshTokenExpiry']);
    localStorage.setItem(this.JWT_TOKEN, tokenData['accessToken']);
    localStorage.setItem(this.REFRESH_TOKEN, tokenData['refreshToken']);
    localStorage.setItem(this.DISPLAY_NAME, tokenData['playerDetails']['displayName']);
    localStorage.setItem(this.JOURNEY_COMPLETE, tokenData['playerDetails']['journeyComplete'].toString())
    localStorage.setItem(this.JOURNEY_VERSION, tokenData['playerDetails']['journeyVersion'].toString())
  }
  
  removeTokensAtLogout() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.ACCESS_TOKEN_EXPIRY);
    localStorage.removeItem(this.REFRESH_TOKEN_EXPIRY);
    localStorage.removeItem(this.ROLE_ID);
    localStorage.removeItem(this.PLAYER_ID);
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.PLAYER_NAME);
    localStorage.removeItem(this.PWD_RESETID);
    localStorage.removeItem(this.DISPLAY_NAME);
    localStorage.removeItem(this.JOURNEY_COMPLETE);
    localStorage.removeItem(this.JOURNEY_VERSION);
    localStorage.removeItem(this.DEPOSIT_CONSENT);
  }
}
