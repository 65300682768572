import { Injectable, signal } from '@angular/core';
import * as contentful from 'contentful';
import { ConfigConstants } from 'config-constants';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  contentfulClient: any;

  constructor() {
    this.contentfulClient = contentful.createClient({ 
        space: ConfigConstants.CONTENTFUL_SPACE_ID,
        accessToken: ConfigConstants.CONTENTFUL_SPACE_ACCESS_TOKEN,
    })
  }

  getGameMeta() {
    return this.contentfulClient.getEntries({ content_type: 'gameMeta' })
  }

}