import { Injectable } from '@angular/core';
import { ConfigConstants } from 'config-constants';

@Injectable({
  providedIn: 'root'
})

export class ParticipantsImageService {
  getEPLImageUrls(url, side, type) {
    let nurl = url.split(".");
    let teamSide = side === 'home' ? '_Home.' : '_Away.';
    if(type === 'survivor') {
      return ConfigConstants.S3_PARTICIPANTS + nurl[0] + teamSide + nurl[1];
    }
  }
  getParticipantsUrls(url:string):string {
    return ConfigConstants.S3_BUCKET + url;
  }
  getHorseRacingParticipantsUrls(date) {
      return ConfigConstants.S3_PARTICIPANTS + "horseracing/silks/" + date + "/";
  }
  getPick6ParticipantsFlags(name) {
      return ConfigConstants.S3_PARTICIPANTS + "golf/" + name + ".svg"
  }
  getWorldCupParticipantsFlags(url: string, large: boolean) {
    let nurl = url.split(".");
    let size = large ? '_L.' : '_M.';
    return ConfigConstants.S3_PARTICIPANTS + nurl[0]+size+nurl[1];
  }

  getFrontAndBackShirts(url: string, front: boolean, basketball = false) {
    if (basketball) {
      return ConfigConstants.S3_PARTICIPANTS + url.replace('svg', 'png');
    }
    const nurl = url.split(".");
    const side = front ? '_3D_front.' : '_3D_back.';
    return ConfigConstants.S3_PARTICIPANTS + nurl[0]+side+'png';
  }

  getBasketballShirts(imageUrl: string) {
    return ConfigConstants.S3_PARTICIPANTS + imageUrl.replace('svg', 'png');
  }

  getNflShirtsHelmets(url: string, key :string, helmet: boolean = false) {
    const side = helmet ? '_helmet.png' : '_front.png';
    return ConfigConstants.S3_BUCKET + url + '/' + key + side;
  }
}
