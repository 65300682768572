<div class="new-page-container">
  <div class="new_footer" [ngStyle]="{'min-height': getMinHeight()}">
    <div class="links">
      <div>
        <ng-container *ngIf="!isLanding">
          <a target="_blank" href="https://www.gamblingcommission.gov.uk/">Gambling Commission</a>
          <a target="_blank" [href]="privacyPolicyLink">Privacy Policy</a>
          <a target="_blank" [href]="cookiePolicyLink">Cookie Policy</a>
          <a target="_blank" [href]="tncLink">Terms & Conditions</a>
          <a *ngIf="affliateName" [routerLink]="['/reach-us']">Contact</a>
        </ng-container>
      </div>
    </div>
    <div class="info">
      <div>
        <p>&#169; Network Gaming {{year}}</p>
        <p>Network Gaming is licensed and regulated in Great Britain by the <a target="_blank" href="https://registers.gamblingcommission.gov.uk/55507">Gambling Commission</a> under
          account number 55507</p>
      </div>
      <div>
        <div class="images">
          <img height="9" width="30" src="assets/icons/visa-logo.svg" />
        </div>
        <div class="images">
          <img height="17" width="28" src="assets/icons/mastercard-logo.svg" />
        </div>
      </div>
    </div>
    <div class="final_icons">
      <div>
        <div class="images">
          <img height="9" width="30" src="assets/icons/visa-logo.svg" />
        </div>
        <div class="images">
          <img height="17" width="28" src="assets/icons/mastercard-logo.svg" />
        </div>
      </div>
      <div>
        <img src="assets/icons/18-plus.svg" />
        <a target="_blank" href="https://www.begambleaware.org/"><img src="assets/icons/be-gamble-aware.svg" /></a>
      </div>
      <div>
        <a target="_blank" href="https://www.ibas-uk.com/"><img src="assets/icons/gambling-comm.svg" /></a>
        <a target="_blank" href="https://www.gamstop.co.uk/"><img src="assets/icons/gamstop.svg" /></a>
      </div>
    </div>
  </div>
</div>