
<div class="new_footer_iframe">
    <iframe width="100%" scrolling="no" src="https://fitzdares.com/footer-network-gaming-app"></iframe>
</div>

<!-- 
  <div class="new_footer">
    <div class="links">
      <div>
        <ng-container *ngIf="!isLanding">
          <a target="_blank" href="https://www.gamblingcommission.gov.uk/">Privacy Policy</a>
          <a target="_blank" [href]="privacyPolicyLink">Virtual Rules</a>
          <a target="_blank" [href]="cookiePolicyLink">Responsible Gambling</a>
          <a target="_blank" [href]="tncLink">Terms and Conditions</a>
          <a [routerLink]="['/reach-us']">Betting Rules</a>
          <a [routerLink]="['/reach-us']">Cookies Policy</a>
          <a [routerLink]="['/reach-us']">Contact Us</a>
        </ng-container>
      </div>
    </div>
    <div class="info">
      <div>
        <p>The Sportsbook and Casino platform is provided by FSB Technology (UK) Ltd which is fully licensed and regulated by the Gambling Comission (000-022201-R-305119-017).</p>
        <p> <a class="info_link" target="_blank" href="https://registers.gamblingcommission.gov.uk/55507"> For license status, click here. </a></p>
        <p>The Sportsbook is licensed in Ireland by FSB Technology (UK) Ltd and is regulated under the Betting (Amendment) Act 2015 (License Number: 1013757).</p>
        <p>Restricted to </p>
      </div>

      <div>
        <div class="images">
          <img height="9" width="30" src="assets/icons/visa-logo.svg" />
        </div>
        <div class="images">
          <img height="17" width="28" src="assets/icons/mastercard-logo.svg" />
        </div>
      </div>

    </div>
    <div class="final_icons">
      <div>
        <div class="images">
          <img height="24" width="44" src="https://assets.fsbtech.com/images/payments/icon-payment-visa.svg" />
        </div>
        <div class="images">
          <img height="24" width="44" src="https://assets.fsbtech.com/images/payments/icon-payment-visa-electron.svg" />
        </div>
        <div class="images">
          <img height="24" width="44" src="https://assets.fsbtech.com/images/payments/icon-payment-mastercard.svg" />
        </div>
        <div class="images">
          <img height="24" width="44" src="https://assets.fsbtech.com/images/payments/icon-payment-maestro.svg" />
        </div>
      </div>
      <div>
        <a aria-label="IBAS" href="http://www.ibas-uk.com/" class="icon-cc-IBAS" target="_blank"></a> 
      </div>

      <div>
        <img src="assets/icons/18-plus.svg" />
        <a target="_blank" href="https://www.begambleaware.org/"><img src="assets/icons/be-gamble-aware.svg" /></a>
      </div>
      <div>
        <a target="_blank" href="https://www.ibas-uk.com/"><img src="assets/icons/gambling-comm.svg" /></a>
        <a target="_blank" href="https://www.gamstop.co.uk/"><img src="assets/icons/gamstop.svg" /></a>
      </div>
    </div>
  </div>
 -->