<div class="bg-white p-4">
    <div *ngIf="allTickets" class="flex">
        <div class="flex-1 mx-2 border border-solid rounded shadow-lg flex justify-between">
            <div class="p-2">Tickets created</div>
            <div class="p-2 bg-slate-200 font-semibold w-1/2 text-center flex justify-center items-center">{{allTickets.length}}</div>
        </div>
        <div class="flex-1 mx-2 border border-solid rounded shadow-lg flex justify-between">
            <div class="p-2">Tickets purchased</div>
            <div class="p-2 bg-slate-200 font-semibold w-1/2 text-center flex justify-center items-center">{{purchasedTickets.length}}</div>
        </div>
        <div class="flex-1 mx-2 border border-solid rounded shadow-lg flex justify-between">
            <div class="p-2">Players purchased</div>
            <div class="p-2 bg-slate-200 font-semibold w-1/2 text-center flex justify-center items-center">{{playersPurchased}}</div>
        </div>
    </div>

    <div class="flex justify-around mt-12 h-96">
        <div class="ticketsCreatedAtChartCanvasContainer">
            <canvas width="1200" height="800" #ticketsCreatedAtChartCanvas>{{ ticketsCreatedAtChart }}</canvas>
        </div>
    </div>
</div>