 import { HttpClient } from '@angular/common/http';
 import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  constructor(http: HttpClient, private router: Router, private activeRoute: ActivatedRoute) {
   }
   logoutUser() {
    // console.log('Logout console:');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    localStorage.removeItem('userid');
    let affName = localStorage.getItem('AFF_NAME')
    this.router.navigate(['/']);
  }

  getToken() {
    return localStorage.getItem('token');
  }
  loggedIn() {
    return !!localStorage.getItem('token');
  }
}
