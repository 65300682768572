<ng-container *ngIf="leaderboardType === 'masters' ">
    <div class="teams competition_lb">
        <div class="team_row masters" *ngFor="let player of playerRankings; let i=index" [id]="'player_item_' + player[uniqueField] + '_'+leaderBoardName" [ngClass]="{'even': i % 2 === 0}">
            <div class="score_row" *ngIf="player">
                <span *ngIf="!showFlag(player)" class="position order bold" style="font-weight: 600; font-size: 17px; line-height: 21px">{{ player.displayPosition || "-" }}{{player.displayPosition !== "-" ? "." : ""}}</span>
                <span *ngIf="showFlag(player)" class="position order bold" style="font-weight: 600; font-size: 17px; line-height: 21px">{{ showFlag(player) }}</span>
                <div class="team_name masters bold">
                        {{ player.firstName + ' ' + player.lastName }} 
                </div>
                <div *ngIf="!showFlag(player)" class="prize bold condensed masters" style="min-height: auto; color: #009070;">
                    {{player.points | number}}
                </div>
                <div *ngIf="showFlag(player)" class="prize bold condensed masters" style="min-height: auto; color: #009070;">
                    {{ player.points | number}}
                </div>
                <div class="current_hole condensed">
                    {{ player.score || "-" }}
                </div>
                <div class="current_hole condensed" style="padding-right: 0px;">
                    {{ player.currentHole || "-" }}
                </div>
            </div>  
        </div>
    </div>
</ng-container>

<ng-container *ngIf="leaderboardType === 'competition'">
    <div class="teams">
        <div class="team_row competition" *ngFor="let item of playerRankings let i=index" [id]="'player_item_' + item[uniqueField] + '_'+leaderBoardName" [ngClass]="{'even': i % 2 === 0, 'highlighted': item.playerId === playerId}">
            <div class="score_row" *ngIf="item.playerId === playerId">
                <span class="position order bold competition_position">{{ item.displayRank }}{{item.displayRank !== "-" ? "." : ""}}</span>
                <div class="team_name__row">
                    <span class="team_name golf bold"><span class="ticketName">{{ item.ticketName }}</span><span style="margin-left: 4px;">(YOU)</span></span> 
                    <div class="participants competition_participants" *ngIf="item.selectionsSummary">
                        <div class="pt1">
                            {{ joinParticipantsNames(item.selectionsSummary)[0].split(", ").slice(0,3).join(", ") }}, 
                        </div>                                
                        <div class="pt2">
                            {{ joinParticipantsNames(item.selectionsSummary)[0].split(", ").slice(3,5).join(", ") }}
                            <span style="opacity: 0.5; margin: 0px 4px;"> | </span>
                            {{ joinParticipantsNames(item.selectionsSummary)[1] }}
                        </div>
                    </div>
                </div>
                <div class="prize bold competition_prize">
                    <div class="lb_points" style="margin-bottom: 7px;">
                        <span class="lb_value" style="color: #000;">
                            {{ showScore(item.points) | number: '1.0-0' }} pts
                        </span>
                    </div>
                    <div class="lb_points">
                        <span class="lb_value" style="color: #000;">
                            £{{ showScore(item.payoutAmount) | number: '1.2-2' }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="score_row" *ngIf="item.playerId !== playerId">
                <div class="position order bold competition_position">
                    {{ item.displayRank }}{{item.displayRank !== "-" ? "." : ""}}
                </div>
                <div class="team_name__row">
                    <span class="team_name golf bold">
                        <span class="ticketName">
                            {{ item.ticketName }} 
                        </span>
                        <span class="player_name">
                            {{ item.displayName }}
                        </span>
                    </span> 

                    <div class="participants competition_participants" *ngIf="item.selectionsSummary">
                        <div class="pt1">
                            {{ joinParticipantsNames(item.selectionsSummary)[0].split(", ").slice(0,3).join(", ") }}, 
                        </div>                                
                        <div class="pt2">
                            {{ joinParticipantsNames(item.selectionsSummary)[0].split(", ").slice(3,5).join(", ") }}
                            <span style="opacity: 0.5; margin: 0px 4px;"> | </span>
                            {{ joinParticipantsNames(item.selectionsSummary)[1] }}
                        </div>
                    </div>
                </div>
                <div class="prize bold competition_prize">
                    <div class="lb_points" style="margin-bottom: 7px;">
                        <span class="lb_value">
                            {{ showScore(item.points) | number: '1.0-0' }} pts
                        </span>
                    </div>
                    <div class="lb_points">
                        <span class="lb_value">
                            £{{ showScore(item.payoutAmount) | number: '1.2-2' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="leaderboardType === 'sfg'">
    <div class="sfg">
        <div class="teams" >
            <div class="team_row" *ngFor="let ticket of playerRankings let i=index"
                 [id]="'player_item_' + ticket[uniqueField] + '_'+leaderBoardName" 
                 (click)="gotoTicketItem(ticket)"
                 [ngClass]="{'odd': i % 2 !== 0, 'highlighted': playerTicketIds.includes(ticket.ticketId)}">
                <div class="score_row">
                    <span class="position order bold" style="margin-right: 15px;">{{ ticket.displayRank || "-" }}</span>
                    <div class="team_name__row">
                        <span class="team_name bold"> {{ ticket.displayName }}</span> 
                    </div>
                    <span *ngIf="togglePotentialPointsAndWinnings" class="prize bold">
                        <span class="highlight">{{showScore(ticket.points) | number: '1.0-0'}}</span>
                        <span class="dull"> &nbsp;/ {{ ticket.potentialPoints }}</span>
                    </span>
                    <span *ngIf="!togglePotentialPointsAndWinnings" class="prize bold">
                        <span class="highlight">{{ ticket.payoutAmount | currency:'GBP' }}</span>
                    </span>
                    <div *ngIf="togglePotentialPointsAndWinnings" class="ticket_name">
                        <img src="../../../assets/icons/team-letter-{{(getTicketName(ticket.ticketName)).toLowerCase()}}.svg" />
                    </div>
                    <div class="angle_right">
                        <span class="icon ng-icon-angle-right"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="leaderboardType === 'horseRacing'">
    <div class="horse_racing">
        <div class="teams">
            <div class="team_row" *ngFor="let ticket of playerRankings let i=index"
            [id]="'player_item_' + ticket[uniqueField] + '_'+leaderBoardName" 
                    [ngClass]="{'highlighted': playerTicketIds.includes(ticket.ticketId)}">
                    <div class="score_row">
                        <span class="position order bold" style="margin-right: 8px;">
                            {{ ticket.displayRank }}.
                            <!-- <span *ngIf="ticket.playerId !== playerId" class="icon ng-icon-star-outline"></span> -->
                            <span (click)="setUnsetFavHorse(ticket.ticketId)" *ngIf="ticket.playerId !== playerId && !favTicketIds.includes(ticket.ticketId)" class="icon ng-icon-star-outline"></span>
                            <span (click)="setUnsetFavHorse(ticket.ticketId)" *ngIf="ticket.playerId !== playerId && favTicketIds.includes(ticket.ticketId)" class="icon ng-icon-star-solid" style="color: #FF9900;"></span>
                        </span>
                        <div class="team_name__row" style="flex: 1;" (click)="gotoTicketItem(ticket)">
                            <span class="team_name bold">
                                {{ ticket.displayName }} {{ ticket.playerId === playerId ? "(YOU)" : "" }}
                                <div class="ticket_name" *ngIf="ticket.ticketName">
                                    <img src="../../../assets/icons/team-letter-{{(getTicketName(ticket.ticketName)).toLowerCase()}}.svg" />
                                </div>
                            </span> 
                        </div>
                        <span class="prize bold" (click)="gotoTicketItem(ticket)">
                            <span class="highlight points">{{showScore(ticket.points) | number: '1.0-0'}} pts</span>
                            <span class="highlight amount">{{ ticket.payoutAmount | currency:'GBP' }}</span>
                        </span>
                        <!-- <div class="angle_right">
                            <span class="icon ng-icon-angle-right"></span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
</ng-container>

