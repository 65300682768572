import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import { AdminApiService } from 'projects/admin/src/api-service/api.service';

Chart.register(...registerables);

@Component({
  selector: 'app-game-tickets',
  templateUrl: './game-tickets.component.html',
  styleUrls: ['./game-tickets.component.scss'],
})
export class GameTicketsComponent implements OnInit {

  @ViewChild('ticketsCreatedAtChartCanvas', {static: true}) private ticketsCreatedAtChartCanvasRef;

  @Input() gameId: any;

  allTickets: any[];
  purchasedTickets: any[];
  unpaidTickets: any[];
  playersPurchased: number;

  ticketsCreatedAtChart: any;

  constructor(private apiService: AdminApiService) { }

  ngOnInit(): void {
    this.apiService.getGameTickets(this.gameId).subscribe(res => {
      this.allTickets = res['data']['tickets'] ?? [];
      this.purchasedTickets = this.allTickets.filter(t => t.paymentStatus === 'SUCCEEDED');
      this.unpaidTickets = this.allTickets.filter(t => t.paymentStatus !== 'SUCCEEDED');

      const playerMap = {};
      this.purchasedTickets.forEach(t => {
        if (!playerMap.hasOwnProperty(t.playerId)) {
          playerMap[t.playerId] = true;
        }
      });
      this.playersPurchased = Object.keys(playerMap).length

      this.createTicketsCreatedAtChart();

    })
  }

  createTicketsCreatedAtChart() {

    const earliestCreatedTicket = this.allTickets.sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix())[0]
    const mostRecentlyCreatedTicket = this.allTickets.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())[0]
    console.log(earliestCreatedTicket)
    let startTime = moment(earliestCreatedTicket.createdAt).startOf('hour');
    const endTime = moment(mostRecentlyCreatedTicket.createdAt).endOf('hour');

    const times = {};
    while (startTime.isBefore(endTime)) {
      times[startTime.toISOString()] = {
        paid: 0,
        unpaid: 0,
      };
      startTime = startTime.add(1, 'hours')
    }

    this.purchasedTickets.forEach(t => {
      if (times[moment(t.createdAt).startOf('hour').toISOString()]) {
        times[moment(t.createdAt).startOf('hour').toISOString()].paid += 1;
      }
    })

    this.unpaidTickets.forEach(t => {
      if (times[moment(t.createdAt).startOf('hour').toISOString()]) {
        times[moment(t.createdAt).startOf('hour').toISOString()].unpaid += 1;
      }
    })

    const labels = [];
    const paidCounts = [];
    const unpaidCounts = [];
    for (const [key, value] of Object.entries(times)) {
      labels.push(moment(key).format('YYYY-MM-DD hA'));
      paidCounts.push(value['paid']);
      unpaidCounts.push(value['unpaid']);
    }

    this.ticketsCreatedAtChart = new Chart(this.ticketsCreatedAtChartCanvasRef.nativeElement, {
      type: 'bar',
      data: {
        labels: labels, 
	      datasets: [
          {
            data: paidCounts,
            backgroundColor: '#00aba9',
            label: 'Number of purchased tickets created'
          },
          {
            data: unpaidCounts,
            backgroundColor: '#b91d47',
            label: 'Number of unpaid tickets created'
          }
        ]
      },
      options: {
        scales: {
          y: {
            ticks: {
              precision: 0
            }
          }
        }
      }
    });
  }
}
