import { environment } from 'environments/environment';
const supportedLanguages = [
   { name: 'English', languageCode: 'en'},
   // { name: 'French', languageCode: 'fr'},
   // { name: 'Spanish', languageCode: 'es'},
   // { name: 'German', languageCode: 'de'},
 ]

export class ConfigConstants {
   public static APP_VERSION = environment.appVersion;
   public static IS_PROD = environment.production;
   public static API_URL = environment.apiUrl;
   public static API_URL_V2 = environment.apiUrl.replace('/v1/','/v2/');
   public static API_URL_V3 = environment.apiUrl.replace('/v1/','/v3/');
   public static LEADERBOARD_SERVICE_URL = environment.leaderboardServiceUrl;
   public static LEADERBOARD_POLLING_INTERVAL = environment.leaderboardPollingInterval;
   public static PA_SERVICE_URL = environment.horseRacePAServiceUrl;
   public static ALLOW_CREDIT = environment.allowCredit;
   public static SHORT_LEADERBOARD_RESULT_LIMIT = environment.shortLeaderboardResultLimit
   public static GOOGLE_TAG_MANAGER_ID  = environment.googleTagManagerId
   public static S3_PARTICIPANTS = environment.s3Participants
   public static S3_BUCKET = environment.s3Bucket
   public static APP_CONFIG_S3_BUCKET = environment.appConfigS3Bucket
   public static JOURNEY_VERSION = environment.journeyVersion
   public static GET_ADDRESS_IO_API_KEY = environment.getAddressIoApiKey
   public static APP_AFFILIATES = environment.affiliates;
   public static PLATFORM_APPS = environment.apps
   public static NOTIFICATION_CENTER = environment.notificationCenter;
   public static SUPPORTED_LANGUAGES = supportedLanguages;
   public static SUPPORT_EMAIL = 'support@networkgaming.io';
   public static ENABLE_SGS_COMMENTARY = environment.enableSgsCommentary;
   public static SGS_MAX_COMMENTS = environment.sgsMaxComments;
   public static MIXPANEL_TOKEN = environment.mixpanelToken;
   public static PLANETSPORT_MIXPANEL_TOKEN = environment.planetsportMixpanelToken;
   public static TALKSPORT_MIXPANEL_TOKEN = environment.talksportMixpanelToken;
   public static FITZDARES_MIXPANEL_TOKEN = environment.fitzdaresMixpanelToken;
   public static DREAMTEAM_MIXPANEL_TOKEN = environment.dreamteamMixpanelToken;
   public static ROCKTHEGOAT_MIXPANEL_TOKEN = environment.rockthegoatMixpanelToken;
   public static RACINGPOST_MIXPANEL_TOKEN = environment.racingpostMixpanelToken;
   public static ODDSCHECKER_MIXPANEL_TOKEN = environment.oddscheckerMixpanelToken;
   public static BOLAVIP_MIXPANEL_TOKEN = environment.bolavipMixpanelToken;
   public static WHOSCORED_MIXPANEL_TOKEN = environment.whoscoredMixpanelToken;
   public static GREATGOALS_MIXPANEL_TOKEN = environment.greatgoalsMixpanelToken;
   public static ATTHERACES_MIXPANEL_TOKEN = environment.attheracesMixpanelToken;
   public static OPERATOR = environment.operator;

   public static HEATMAP = environment.heatmap;
   public static HEATMAP_SYNC = environment.heatmapSync;

   public static LOBBY_HELP_ICON = environment.lobbyHelpIcon;

   public static CONTENTFUL_SPACE_ID = environment.contentfulSpaceId;
   public static CONTENTFUL_SPACE_ACCESS_TOKEN = environment.contentfulSpaceAccessToken;

   public static ONBOARDING_JOURNEY = environment.onboardingJourney;
   public static CURRENCY_ID = environment.currencyId;
   public static FSB_FOOTER_IFRAME_URL = environment.fsbFooterIframeURL;
}
