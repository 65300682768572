import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService, ThemeService } from '.';
import { BehaviorSubject } from 'rxjs';
import { ConfigConstants } from 'config-constants';

@Injectable({
  providedIn: 'root'
})
export class AffiliateManager {
    public readonly FITZDARES = 'FITZDARES'
    public readonly TALKSPORT = 'TALKSPORT'
    public readonly DREAMTEAM = 'DREAMTEAM'
    public readonly ROCKTHEGOAT = 'ROCKTHEGOAT'
    public readonly RACINGPOST = 'RACINGPOST'
    public readonly ODDSCHECKER = 'ODDSCHECKER'
    public readonly BOLAVIP = 'BOLAVIP'
    public readonly WHOSCORED = 'WHOSCORED'
    public readonly PLANETSPORT = 'PLANETSPORT'
    public readonly GREATGOALS = 'GREATGOALS'
    public readonly ATTHERACES = 'ATTHERACES'
    public readonly NATIONALWORLD = 'NATIONALWORLD'
    public readonly FITZDARESSPORTSBOOK = 'FITZDARESSPORTSBOOK'
    public readonly ALPHA = 'ALPHA'

    public readonly FTZ = 'FTZ'
    public readonly TS = 'TS'
    public readonly DT = 'DT'
    public readonly RTG = 'RTG'
    public readonly RPOST = 'RPOST'
    public readonly OC = 'OC'
    public readonly BVIP = 'BVIP'
    public readonly WS = 'WS'
    public readonly PS = 'PS'
    public readonly GG = 'GG'
    public readonly ATR = 'ATR'
    public readonly NW = 'NW'
    public readonly FSB = 'FSB'
    public readonly AL = 'AL'


    // How To Play Links
    public readonly FTZ_HTPL = 'https://fantasy.fitzdares.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=Fitzdares%20Big5_HTP&utm_campaign=Big5PL';
    public readonly TS_HTPL = 'https://games.talksport.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=talkSPORT%20Big5_HTP&utm_campaign=Big5PL';
    public readonly DT_HTPL = 'https://games.dreamteamfc.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20Big5_HTP&utm_campaign=Big5PL';
    public readonly RTG_HTPL = 'https://rockthegoat.co/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Big5_HTP&utm_campaign=Big5PL';
    public readonly RPOST_HTPL = 'https://games.dreamteamfc.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20Big5_HTP&utm_campaign=Big5PL';
    public readonly OC_HTPL = 'https://games.dreamteamfc.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20Big5_HTP&utm_campaign=Big5PL';
    public readonly BVIP_HTPL = 'https://games.dreamteamfc.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20Big5_HTP&utm_campaign=Big5PL';
    public readonly WS_HTPL = 'https://games.whoscored.com/football/big5?utm_source=NetworkGaming%20App&utm_medium=WhoScored%20Big5_HTP&utm_campaign=Big5PL';
    public readonly PS_HTPL = 'https://worldcup.planetsport.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=PlanetSport%20Big5_HTP&utm_campaign=Big5PL';
    public readonly GG_HTPL = 'https://games.101greatgoals.com/football/big5?utm_source=NetworkGaming%20App&utm_medium=101GreatGoals%20Big5_HTP&utm_campaign=Big5PL';
    public readonly ATR_HTPL = 'https://games.attheraces.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=AtTheRaces%20Big5_HTP&utm_campaign=Big5PL';
    public readonly NW_HTPL = 'https://games.nationalworld.com/football/big5/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=NationalWorld%20Big5_HTP&utm_campaign=Big5PL';

    // How To Play Golf Links
    public readonly FTZ_HTPLG = 'https://fantasy.fitzdares.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=Fitzdares%20FantasyGolf_HTP&utm_campaign=FantasyGolf';
    public readonly TS_HTPLG = 'http://games.talksport.com/golf/how-to-play?utm_source=NetworkGaming%20App&utm_medium=talkSPORT%20FantasyGolf_HTP&utm_campaign=FantasyGolf';
    public readonly RTG_HTPLG = 'https://rockthegoat.co/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20&utm_campaign=FantasyGolf';
    public readonly DT_HTPLG = 'https://games.dreamteamfc.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20&utm_campaign=FantasyGolf';
    public readonly RPOST_HTPLG = 'https://rockthegoat.co/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RacingPost%20&utm_campaign=FantasyGolf';
    public readonly OC_HTPLG = 'https://rockthegoat.co/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=OddsChecker%20&utm_campaign=FantasyGolf';
    public readonly BVIP_HTPLG = 'https://rockthegoat.co/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=BolaVIP%20&utm_campaign=FantasyGolf';
    public readonly WS_HTPLG = 'https://games.whoscored.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=WhoScored%20&utm_campaign=FantasyGolf';
    public readonly PS_HTPLG = 'https://worldcup.planetsport.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=PlanetSport%20&utm_campaign=FantasyGolf';
    public readonly GG_HTPLG = 'https://games.101greatgoals.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=101GreatGoals%20&utm_campaign=FantasyGolf';
    public readonly ATR_HTPLG = 'https://games.attheraces.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=AtTheRaces%20&utm_campaign=FantasyGolf';
    public readonly NW_HTPLG = 'https://games.nationalworld.com/golf/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=NationalWorld%20&utm_campaign=FantasyGolf';

    // Game Rules Links
    public readonly FTZ_RULES = 'https://fantasy.fitzdares.com/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=Fitzdares%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly TS_RULES = 'https://games.talksport.com/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=talkSPORT%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly DT_RULES = 'https://games.dreamteamfc.com/game-rules/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly RTG_RULES = 'https://rockthegoat.co/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly RPOST_RULES = 'https://rockthegoat.co/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly OC_RULES = 'https://rockthegoat.co/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly BVIP_RULES = 'https://rockthegoat.co/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly WS_RULES = 'https://games.whoscored.com/football/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=WhoScored%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly PS_RULES = 'https://worldcup.planetsport.com/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=PlanetSport%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly GG_RULES = 'https://games.101greatgoals.com/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=101GreatGoals%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly ATR_RULES = 'https://games.attheraces.com/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=AtTheRaces%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly NW_RULES = 'https://games.nationalworld.com/football/game-rules/?utm_source=NetworkGaming%20App&utm_medium=NationalWorld%20Survivor_HTP&utm_campaign=SurvivorPL';

    // WCS Game Rules Links
    public readonly FTZ_WCS_RULES = 'https://fantasy.fitzdares.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=Fitzdares%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly TS_WCS_RULES = 'https://games.talksport.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=talkSPORT%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly DT_WCS_RULES = 'https://games.dreamteamfc.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly RTG_WCS_RULES = 'https://rockthegoat.co/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly RPOST_WCS_RULES = 'https://rockthegoat.co/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly OC_WCS_RULES = 'https://rockthegoat.co/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly BVIP_WCS_RULES = 'https://rockthegoat.co/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly WS_WCS_RULES = 'https://games.whoscored.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=WhoScored%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly PS_WCS_RULES = 'https://worldcup.planetsport.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=PlanetSport%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly GG_WCS_RULES = 'https://games.101greatgoals.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=101GreatGoals%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly ATR_WCS_RULES = 'https://games.attheraces.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=AtTheRaces%20Survivor_HTP&utm_campaign=SurvivorPL';
    public readonly NW_WCS_RULES = 'https://games.nationalworld.com/football/tournamentsurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=NationalWorld%20Survivor_HTP&utm_campaign=SurvivorPL';
    
    // SGS how to play links
    public readonly RTG_SGS_HTP = 'https://rockthegoat.co/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20SGS_HTP&utm_campaign=SGS';
    public readonly WS_SGS_HTP = 'https://games.whoscored.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=WhoScored%20SGS_HTP&utm_campaign=SGS';
    public readonly TS_SGS_HTP = 'https://games.talksport.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=talkSPORT%20SGS_HTP&utm_campaign=SGS';
    public readonly DT_SGS_HTP = 'https://games.dreamteamfc.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20SGS_HTP&utm_campaign=SGS';
    public readonly PS_SGS_HTP = 'https://worldcup.planetsport.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=PlanetSport%20SGS_HTP&utm_campaign=SGS';
    public readonly GG_SGS_HTP = 'https://games.101greatgoals.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=101GreatGoals%20SGS_HTP&utm_campaign=SGS'; 
    public readonly ATR_SGS_HTP = 'https://games.attheraces.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=AtTheRaces%20SGS_HTP&utm_campaign=SGS'; 
    public readonly FTZ_SGS_HTP = 'https://fantasy.fitzdares.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=Fitzdares%20SGS_HTP&utm_campaign=SGS';
    public readonly NW_SGS_HTP = 'https://games.nationalworld.com/singlegamesurvivor/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=NationalWorld%20SGS_HTP&utm_campaign=SGS'; 
    
    // FIB how to play links
    public readonly RTG_FIB_HTP = 'https://rockthegoat.co/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=RTG%20ShoutRacing_HTP&utm_campaign=ShoutRacing';
    public readonly WS_FIB_HTP = 'https://games.whoscored.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=WhoScored%20ShoutRacing_HTP&utm_campaign=ShoutRacing';
    public readonly TS_FIB_HTP = 'https://games.talksport.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=talkSPORT%20ShoutRacing_HTP&utm_campaign=ShoutRacing';
    public readonly DT_FIB_HTP = 'https://games.dreamteamfc.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=DreamTeam%20ShoutRacing_HTP&utm_campaign=ShoutRacing';
    public readonly PS_FIB_HTP = 'https://worldcup.planetsport.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=PlanetSport%20ShoutRacing_HTP&utm_campaign=ShoutRacing';
    public readonly GG_FIB_HTP = 'https://games.101greatgoals.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=101GreatGoals%20ShoutRacing_HTP&utm_campaign=ShoutRacing'; 
    public readonly ATR_FIB_HTP = 'https://games.attheraces.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=AtTheRaces%20ShoutRacing_HTP&utm_campaign=ShoutRacing'; 
    public readonly FTZ_FIB_HTP = 'https://fantasy.fitzdares.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=Fitzdares%20ShoutRacing_HTP&utm_campaign=ShoutRacing';
    public readonly NW_FIB_HTP = 'https://games.nationalworld.com/shoutracing/how-to-play/?utm_source=NetworkGaming%20App&utm_medium=NationalWorld%20ShoutRacing_HTP&utm_campaign=ShoutRacing'; 

    public readonly FTZ_PRIVACY_POLICY = 'https://fantasy.fitzdares.com/privacy-policy/';
    public readonly TS_PRIVACY_POLICY = 'https://games.talksport.com/privacy-policy/';
    public readonly DT_PRIVACY_POLICY = 'https://dreamteamselector.com/privacy-policy/';
    public readonly RTG_PRIVACY_POLICY = 'https://rockthegoat.co/privacy-policy/';
    public readonly RPOST_PRIVACY_POLICY = 'https://rockthegoat.co/privacy-policy/';
    public readonly OC_PRIVACY_POLICY = 'https://rockthegoat.co/privacy-policy/';
    public readonly BVIP_PRIVACY_POLICY = 'https://rockthegoat.co/privacy-policy/';
    public readonly WS_PRIVACY_POLICY = 'https://games.whoscored.com/privacy-policy/';
    public readonly PS_PRIVACY_POLICY = 'https://worldcup.planetsport.com/privacy-policy/';
    public readonly GG_PRIVACY_POLICY = 'https://games.101greatgoals.com/privacy-policy/';
    public readonly ATR_PRIVACY_POLICY = 'https://games.attheraces.com/privacy-policy/';
    public readonly NW_PRIVACY_POLICY = 'https://games.nationalworld.com/privacy-policy/';

    public readonly FTZ_COOKIE_POLICY = 'https://fantasy.fitzdares.com/cookie-policy/';
    public readonly TS_COOKIE_POLICY = 'https://games.talksport.com/cookie-policy/';
    public readonly DT_COOKIE_POLICY = 'https://dreamteamselector.com/cookie-policy/';
    public readonly RTG_COOKIE_POLICY = 'https://rockthegoat.co/cookie-policy/';
    public readonly RPOST_COOKIE_POLICY = 'https://rockthegoat.co/cookie-policy/';
    public readonly OC_COOKIE_POLICY = 'https://rockthegoat.co/cookie-policy/';
    public readonly BVIP_COOKIE_POLICY = 'https://rockthegoat.co/cookie-policy/';
    public readonly WS_COOKIE_POLICY = 'https://games.whoscored.com/cookie-policy/';
    public readonly PS_COOKIE_POLICY = 'https://worldcup.planetsport.com/cookie-policy/';
    public readonly GG_COOKIE_POLICY = 'https://games.101greatgoals.com/cookie-policy/';
    public readonly ATR_COOKIE_POLICY = 'https://games.attheraces.com/cookie-policy/';
    public readonly NW_COOKIE_POLICY = 'https://games.nationalworld.com/cookie-policy/';

    public readonly FTZ_TnC = 'https://fantasy.fitzdares.com/terms-conditions/';
    public readonly TS_TnC = 'https://games.talksport.com/terms-conditions/';
    public readonly DT_TnC = 'https://dreamteamselector.com/terms-conditions/';
    public readonly RTG_TnC = 'https://rockthegoat.co/terms-conditions/';
    public readonly RPOST_TnC = 'https://rockthegoat.co/terms-conditions/';
    public readonly OC_TnC = 'https://rockthegoat.co/terms-conditions/';
    public readonly BVIP_TnC = 'https://rockthegoat.co/terms-conditions/';
    public readonly WS_TnC = 'https://games.whoscored.com/terms-conditions/';
    public readonly PS_TnC = 'https://worldcup.planetsport.com/terms-conditions/';
    public readonly GG_TnC = 'https://games.101greatgoals.com/terms-conditions/';
    public readonly ATR_TnC = 'https://games.attheraces.com/terms-conditions/';
    public readonly NW_TnC = 'https://games.nationalworld.com/terms-conditions/';

    private clientName: string;
    private affId: number = 0;
    private affName: string = '';

    private affiliateStore = new BehaviorSubject<any>(null)
    affiliateObserver = this.affiliateStore.asObservable();

    constructor(
      private themeService: ThemeService,
      private router: Router,
      private toastrService: ToastrService,
      private localStorageService: LocalStorageService,
    ){}

    getAllAffiliates() {
      return [
        this.FITZDARES,
        this.TALKSPORT,
        this.DREAMTEAM,
        this.ROCKTHEGOAT,
        this.RACINGPOST,
        this.ODDSCHECKER,
        this.BOLAVIP,
        this.WHOSCORED,
        this.PLANETSPORT,
        this.GREATGOALS,
        this.ATTHERACES,
        this.NATIONALWORLD,
        this.FITZDARESSPORTSBOOK,
        this.ALPHA
      ]
    }

    getAffiliateAbbreviation(affName) {
      if(affName === 'fitzdares') {
        return this.FTZ
      } else if (affName === 'talksport') {
        return this.TS
      } else if (affName === 'dreamteam') {
        return this.DT
      } else if (affName === 'rockthegoat') {
        return this.RTG
      } else if (affName === 'racingpost') {
        return this.RPOST
      } else if (affName === 'oddschecker') {
        return this.OC
      }else if (affName === 'bolavip') {
        return this.BVIP
      } else if (affName === 'whoscored') {
        return this.WS
      } else if (affName === 'planetsport') {
        return this.PS
      } else if (affName === 'greatgoals') {
        return this.GG
      } else if (affName === 'attheraces') {
        return this.ATR
      } else if (affName === 'nationalworld') {
        return this.NW
      } else if (affName === 'fitzdaressportsbook') {
        return this.FSB
      } else if (affName === 'alpha') {
        return this.AL
      }
    }

    getAffiliateFromAbbreviation(affAbrv) {
      if(affAbrv === this.FTZ) {
        return 'fitzdares'
      } else if (affAbrv === this.TS) {
        return 'talksport'
      } else if (affAbrv === this.DT) {
        return 'dreamteam'
      } else if (affAbrv === this.RTG) {
        return 'rockthegoat'
      } else if (affAbrv === this.RPOST) {
        return 'racingpost'
      } else if (affAbrv === this.OC) {
        return 'oddschecker'
      }else if (affAbrv === this.BVIP) {
        return 'bolavip'
      } else if (affAbrv === this.WS) {
        return 'whoscored'
      } else if (affAbrv === this.PS) {
        return 'planetsport'
      } else if (affAbrv === this.GG) {
        return 'greatgoals'
      } else if (affAbrv === this.ATR) {
        return 'attheraces'
      } else if (affAbrv === this.NW) {
        return 'nationalworld'
      } else if (affAbrv === this.FSB) {
        return 'fitzdaressportsbook'
      } else if (affAbrv === this.AL) {
        return 'alpha'
      }
    }

    getAffiliateIdByName(affName) {
      if(affName === 'fitzdares') {
        return 1
      } else if (affName === 'talksport') {
        return 2
      } else if (affName === 'dreamteam') {
        return 3
      } else if (affName === 'rockthegoat') {
        return 4
      } else if (affName === 'racingpost') {
        return 5
      } else if (affName === 'oddschecker') {
        return 7
      }else if (affName === 'bolavip') {
        return 8
      } else if (affName === 'whoscored') {
        return 9
      } else if (affName === 'planetsport') {
        return 10
      } else if (affName === 'greatgoals') {
        return 11
      } else if (affName === 'attheraces') {
        return 12
      } else if (affName === 'nationalworld') {
        return 13
      } else if (affName === 'fitzdaressportsbook') {
        return 15
      } else if (affName === 'alpha') {
        return 16
      }
    }

    getMarketingName() {
      const affId = Number(localStorage.getItem('AFF_ID'))
      if(affId === 1) return 'Fitzdares Fantasy Games';
      else if (affId === 2) return 'talkSPORT Games';
      else if (affId === 3) return 'Dream Team Extra';
      else if (affId === 4) return 'Rock The Goat';
      else if (affId === 5) return 'Racing Post';
      else if (affId === 7) return 'Odds Checker';
      else if (affId === 8) return 'BolaVIP';
      else if (affId === 9) return 'WhoScored';
      else if (affId === 10) return 'Planet Sport Games';
      else if (affId === 11) return '101 Great Goals';
      else if (affId === 12) return 'At The Races';
      else if (affId === 13) return 'National World';
      else if (affId === 15) return 'FSB Operator';
      else if (affId === 16) return 'Alpha Platform';

    }

    getAffiliateNameForLobby(affName) {
      // For lobby.component, the games have affiliate as RTG instead of rockthegoat
      if (affName === 'rockthegoat') {
        return this.RTG
      } else {
        return affName;
      }
    }

    setAffiliateToStorage(affName) {
      if(affName === 'fitzdares') {
        localStorage.setItem('AFF_ID', '1');
        localStorage.setItem('AFF_NAME', 'fitzdares');
      } else if (affName === 'talksport') {
        localStorage.setItem('AFF_ID', '2');
        localStorage.setItem('AFF_NAME', 'talksport');
      } else if (affName === 'dreamteam') {
        localStorage.setItem('AFF_ID', '3');
        localStorage.setItem('AFF_NAME', 'dreamteam');
      } else if (affName === 'rockthegoat') {
        localStorage.setItem('AFF_ID', '4');
        localStorage.setItem('AFF_NAME', 'rockthegoat');
      }  else if (affName === 'racingpost') {
        localStorage.setItem('AFF_ID', '5');
        localStorage.setItem('AFF_NAME', 'racingpost');
      }  else if (affName === 'oddschecker') {
        localStorage.setItem('AFF_ID', '7'); // 6 is apollo booksmaker
        localStorage.setItem('AFF_NAME', 'oddschecker');
      } else if (affName === 'bolavip') {
        localStorage.setItem('AFF_ID', '8');
        localStorage.setItem('AFF_NAME', 'bolavip');
      } else if (affName === 'whoscored') {
        localStorage.setItem('AFF_ID', '9');
        localStorage.setItem('AFF_NAME', 'whoscored');
      } else if (affName === 'planetsport') {
        localStorage.setItem('AFF_ID', '10');
        localStorage.setItem('AFF_NAME', 'planetsport');
      } else if (affName === 'greatgoals') {
        localStorage.setItem('AFF_ID', '11');
        localStorage.setItem('AFF_NAME', 'greatgoals');
      } else if (affName === 'attheraces') {
        localStorage.setItem('AFF_ID', '12');
        localStorage.setItem('AFF_NAME', 'attheraces');
      } else if (affName === 'nationalworld') {
        localStorage.setItem('AFF_ID', '13');
        localStorage.setItem('AFF_NAME', 'nationalworld');
      } else if (affName === 'fitzdaressportsbook') {
        localStorage.setItem('AFF_ID', '15');
        localStorage.setItem('AFF_NAME', 'fitzdaressportsbook');
      } else if (affName === 'alpha') {
        localStorage.setItem('AFF_ID', '16');
        localStorage.setItem('AFF_NAME', 'alpha');
      }
      this.affiliateStore.next(affName)
      this.setThemeByAffId(Number(localStorage.getItem('AFF_ID')))
    }

    getAffNameByAffId(affId) {
      if(affId === 1) return 'fitzdares';
      else if (affId === 2) return 'talksport';
      else if (affId === 3) return 'dreamteam';
      else if (affId === 4) return 'rockthegoat';
      else if (affId === 5) return 'racingpost';
      else if (affId === 7) return 'oddschecker';
      else if (affId === 8) return 'bolavip';
      else if (affId === 9) return 'whoscored';
      else if (affId === 10) return 'planetsport';
      else if (affId === 11) return 'greatgoals';
      else if (affId === 12) return 'attheraces';
      else if (affId === 13) return 'nationalworld';
      else if (affId === 15) return 'fitzdaressportsbook';
      else if (affId === 16) return 'alpha';
    }

    getAffName() {
      const affId = Number(localStorage.getItem('AFF_ID'))
      if(affId === 1) return 'fitzdares';
      else if (affId === 2) return 'talksport';
      else if (affId === 3) return 'dreamteam';
      else if (affId === 4) return 'rockthegoat';
      else if (affId === 5) return 'racingpost';
      else if (affId === 7) return 'oddschecker';
      else if (affId === 8) return 'bolavip';
      else if (affId === 9) return 'whoscored';
      else if (affId === 10) return 'planetsport';
      else if (affId === 11) return 'greatgoals';
      else if (affId === 12) return 'attheraces';
      else if (affId === 13) return 'nationalworld';
      else if (affId === 15) return 'fitzdaressportsbook';
      else if (affId === 16) return 'alpha';
    }

    setThemeByAffId(affId) {
      if(affId === 1) return this.themeService.setFitzdaresTheme();
      else if (affId === 2) return this.themeService.setTalksportTheme();
      else if (affId === 3) return this.themeService.setDreamteamTheme()
      else if (affId === 4) return this.themeService.setRTGTheme();
      else if (affId === 5) return this.themeService.setRacingpostTheme();
      else if (affId === 7) return this.themeService.setOddscheckerTheme();
      else if (affId === 8) return this.themeService.setBolavipTheme();
      else if (affId === 9) return this.themeService.setWhoScoredTheme();
      else if (affId === 10) return this.themeService.setPlanetSportTheme();
      else if (affId === 11) return this.themeService.setGreatGoalsTheme();
      else if (affId === 12) return this.themeService.setAtTheRacesTheme();
      else if (affId === 13) return this.themeService.setNationalWorldTheme();
      else if (affId === 15) return this.themeService.setFitzdaresSportsBookTheme();
      else if (affId === 16) return this.themeService.setAlphaTheme();
    }

    getHowToPlayLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_HTPL;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_HTPL;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_HTPL;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_HTPL;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_HTPL;
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_HTPL;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_HTPL;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_HTPL;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_HTPL;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_HTPL;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_HTPL;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_HTPL;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_HTPL;

      else return this.RTG_HTPL;
    }

    getGolfRulesLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_HTPLG;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_HTPLG;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_HTPLG;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_HTPLG;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_HTPLG;
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_HTPLG;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_HTPLG;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_HTPLG;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_HTPLG;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_HTPLG;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_HTPLG;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_HTPLG;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_HTPLG;

      else return this.RTG_HTPLG;
    }

    getPrivacyPolicyLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_PRIVACY_POLICY;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_PRIVACY_POLICY;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_PRIVACY_POLICY;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_PRIVACY_POLICY;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_PRIVACY_POLICY;
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_PRIVACY_POLICY;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_PRIVACY_POLICY;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_PRIVACY_POLICY;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_PRIVACY_POLICY;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_PRIVACY_POLICY;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_PRIVACY_POLICY;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_PRIVACY_POLICY;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_PRIVACY_POLICY;

      else return this.RTG_PRIVACY_POLICY;
    }

    getCookieLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_COOKIE_POLICY;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_COOKIE_POLICY;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_COOKIE_POLICY;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_COOKIE_POLICY;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_COOKIE_POLICY
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_COOKIE_POLICY;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_COOKIE_POLICY;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_COOKIE_POLICY;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_COOKIE_POLICY;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_COOKIE_POLICY;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_COOKIE_POLICY;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_COOKIE_POLICY;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_COOKIE_POLICY;

      else return this.RTG_COOKIE_POLICY;
    }

    getTnCLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_TnC;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_TnC;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_TnC;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_TnC;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_TnC
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_TnC;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_TnC;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_TnC;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_TnC;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_TnC;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_TnC;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_TnC;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_TnC;

      else return this.RTG_TnC;
    }

    getGameRulesLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_RULES;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_RULES;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_RULES;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_RULES;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_RULES;
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_RULES;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_RULES;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_RULES;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_RULES;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_RULES;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_RULES;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_RULES;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_RULES;


      else return this.RTG_RULES;
    }

    getTournamentSurvivorGameRulesLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if(this.affName ===  this.FITZDARES.toLowerCase()) return this.FTZ_WCS_RULES;
      else if(this.affName ===  this.TALKSPORT.toLowerCase()) return this.TS_WCS_RULES;
      else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return this.DT_WCS_RULES;
      else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return this.RTG_WCS_RULES;
      else if(this.affName === this.RACINGPOST.toLowerCase()) return this.RPOST_WCS_RULES;
      else if(this.affName === this.ODDSCHECKER.toLowerCase()) return this.OC_WCS_RULES;
      else if(this.affName === this.BOLAVIP.toLowerCase()) return this.BVIP_WCS_RULES;
      else if(this.affName === this.WHOSCORED.toLowerCase()) return this.WS_WCS_RULES;
      else if(this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_WCS_RULES;
      else if(this.affName === this.GREATGOALS.toLowerCase()) return this.GG_WCS_RULES;
      else if(this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_WCS_RULES;
      else if(this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_WCS_RULES;
      else if(this.affName === this.ALPHA.toLowerCase()) return this.FTZ_WCS_RULES;

      else return this.RTG_RULES;
    }
  
    getSGSHowtoPlayLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if (this.affName === this.FITZDARES.toLowerCase()) return this.FTZ_SGS_HTP;
      else if (this.affName === this.TALKSPORT.toLowerCase()) return this.TS_SGS_HTP;
      else if (this.affName === this.DREAMTEAM.toLowerCase()) return this.DT_SGS_HTP;
      else if (this.affName === this.ROCKTHEGOAT.toLowerCase()) return this.RTG_SGS_HTP;
      else if (this.affName === this.WHOSCORED.toLowerCase()) return this.WS_SGS_HTP;
      else if (this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_SGS_HTP;
      else if (this.affName === this.GREATGOALS.toLowerCase()) return this.GG_SGS_HTP;
      else if (this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_SGS_HTP;
      else if (this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_SGS_HTP;
      else if (this.affName === this.ALPHA.toLowerCase()) return this.FTZ_SGS_HTP;

      else return this.RTG_SGS_HTP;
    }
  
    getFIBHowtoPlayLink() {
      this.affName = localStorage.getItem("AFF_NAME")
      if (this.affName === this.FITZDARES.toLowerCase()) return this.FTZ_FIB_HTP;
      else if (this.affName === this.TALKSPORT.toLowerCase()) return this.TS_FIB_HTP;
      else if (this.affName === this.DREAMTEAM.toLowerCase()) return this.DT_FIB_HTP;
      else if (this.affName === this.ROCKTHEGOAT.toLowerCase()) return this.RTG_FIB_HTP;
      else if (this.affName === this.WHOSCORED.toLowerCase()) return this.WS_FIB_HTP;
      else if (this.affName === this.PLANETSPORT.toLowerCase()) return this.PS_FIB_HTP;
      else if (this.affName === this.GREATGOALS.toLowerCase()) return this.GG_FIB_HTP;
      else if (this.affName === this.ATTHERACES.toLowerCase()) return this.ATR_FIB_HTP;
      else if (this.affName === this.NATIONALWORLD.toLowerCase()) return this.NW_FIB_HTP;
      else if (this.affName === this.ALPHA.toLowerCase()) return this.FTZ_FIB_HTP;
      else return this.RTG_SGS_HTP;
    }

    getAsItStandsSurvivorLeaderboardBGImg(status) {
      this.affName = localStorage.getItem("AFF_NAME")
      if(status === 'SURVIVOR') {
        if(this.affName ===  this.FITZDARES.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-fitzdares.jpg';
        else if(this.affName ===  this.TALKSPORT.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';
        else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';
        else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.RACINGPOST.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.ODDSCHECKER.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.BOLAVIP.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.WHOSCORED.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.PLANETSPORT.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.GREATGOALS.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.ATTHERACES.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.NATIONALWORLD.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.FITZDARESSPORTSBOOK.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.ALPHA.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-fitzdares.jpg';

      }
      if(status === 'ELIMINATED') {
        if(this.affName ===  this.FITZDARES.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-fitzdares.jpg';
        else if(this.affName ===  this.TALKSPORT.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';
        else if(this.affName ===  this.DREAMTEAM.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';
        else if(this.affName ===  this.ROCKTHEGOAT.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';  
        else if(this.affName ===  this.RACINGPOST.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';  
        else if(this.affName ===  this.ODDSCHECKER.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';  
        else if(this.affName ===  this.BOLAVIP.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';  
        else if(this.affName ===  this.WHOSCORED.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.PLANETSPORT.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.GREATGOALS.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.ATTHERACES.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.NATIONALWORLD.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-survivor-talksport.jpg';  
        else if(this.affName ===  this.FITZDARESSPORTSBOOK.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-talksport.jpg';  
        else if(this.affName ===  this.ALPHA.toLowerCase()) return 'https://fantasy-srvr-prod-1-01.s3.eu-west-2.amazonaws.com/fantasy/asitstands/asitstands-eliminated-fitzdares.jpg';
      }
    }

    validateAffiliate(affiliateName){
      const affiliateAbrv = this.getAffiliateAbbreviation(affiliateName)
      const affiliateId = this.getAffiliateIdByName(affiliateName)
      if(ConfigConstants.APP_AFFILIATES.includes(affiliateAbrv)){
        this.setAffiliateToStorage(affiliateName)
        this.setThemeByAffId(affiliateId)
        return true
      } else {
        return false
      }
    }


  pushToAffiliate(url) {
    if (url.startsWith('/partner/fitzdares/register')) {
      this.router.navigate(['/partner/fitzdares/register'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/talksport/register')) {
      this.router.navigate(['/partner/talksport/register'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/dreamteam/register')) {
      this.router.navigate(['/partner/dreamteam/register'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/rockthegoat/register')) {
      this.router.navigate(['/partner/rockthegoat/register'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/racingpost/register')) {
      this.router.navigate(['/partner/racingpost/register'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/oddschecker/register')) {
      this.router.navigate(['/partner/oddschecker/register'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/bolavip/register')) {
      this.router.navigate(['/partner/bolavip/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/whoscored/register')) {
      this.router.navigate(['/partner/whoscored/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/planetsport/register')) {
      this.router.navigate(['/partner/planetsport/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/101greatgoals/register')) {
      this.router.navigate(['/partner/101greatgoals/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/attheraces/register')) {
      this.router.navigate(['/partner/attheraces/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/nationalworld/register')) {
      this.router.navigate(['/partner/nationalworld/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/alpha/register')) {
      this.router.navigate(['/partner/alpha/register'], { queryParamsHandling: 'preserve' });
      return true;
    }  else if (url.startsWith('/partner/fitzdares')) {
      this.router.navigate(['/partner/fitzdares'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/talksport')) {
      this.router.navigate(['/partner/talksport'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/dreamteam')) {
      this.router.navigate(['/partner/dreamteam'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/rockthegoat')) {
      this.router.navigate(['/partner/rockthegoat'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/racingpost')) {
      this.router.navigate(['/partner/racingpost'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/oddschecker')) {
      this.router.navigate(['/partner/oddschecker'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/bolavip')) {
      this.router.navigate(['/partner/bolavip'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/whoscored')) {
      this.router.navigate(['/partner/whoscored'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/planetsport')) {
      this.router.navigate(['/partner/planetsport'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/101greatgoals')) {
      this.router.navigate(['/partner/101greatgoals'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/attheraces')) {
      this.router.navigate(['/partner/attheraces'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/nationalworld')) {
      this.router.navigate(['/partner/nationalworld'], { queryParamsHandling: 'preserve' });
      return true;
    } else if (url.startsWith('/partner/alpha')) {
      this.router.navigate(['/partner/alpha'], { queryParamsHandling: 'preserve' });
      return true;
    }
    return false;
  }

}