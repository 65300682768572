export const environment = {
  appVersion: '0735f04b1c69c5707221001e95dfede3761405e8',
  production: true,

  apiUrl: 'https://api.dev.networkgaming.co.uk/v1/',
  leaderboardServiceUrl: 'https://lb.dev.networkgaming.co.uk/',
  leaderboardPollingInterval: 60000, //milliseconds
  horseRacePAServiceUrl: 'https://pa.dev.networkgaming.co.uk',
  defaultActiveTab: 'survivor', // options: 'pick6' or 'survivor'
  showTabs: ['survivor', 'pick6'],
  allowCredit: true,
  shortLeaderboardResultLimit: 200,
  googleTagManagerId: 'GTM-T7PKJ6L',
  s3Participants: 'https://fantasysrvr-dev-01.s3.eu-west-2.amazonaws.com/participants/',
  s3Bucket: 'https://fantasysrvr-dev-01.s3.eu-west-2.amazonaws.com',
  appConfigS3Bucket: 'https://fantasysrvr-dev-01.s3.eu-west-2.amazonaws.com',
  environment_name: 'dev',
  googleAnalyticId: 'G-L3C33KHX3D',
  journeyVersion: 'v3.0.0',
  getAddressIoApiKey: 'dtoken_hEDzcyiWMr2jSDJvtm84DOKHeNdeEL8H3_Rcl3vW8mWQEP_nIxCGfI1eyhADhPZyoHfyuboeWzrDpsqXrz0x8h70pxgbHaDJVQFY6yu9V3Df2tg4TYXVobWM3SmxM228eG6JxkyD733xm4k6Bj-gTLGTcDDj9mszjz8K75TNioaR4rbsmUcVBvRDuVlUspJ_G4yYrhh5B2_ogSVjruFX4DKIIu0dkTKW',
  notificationCenter: true,
  apps: ['player', 'admin'],
  affiliates: ['FTZ', 'RTG', 'ATR'],   // ts, ftz, rtg, dt, rpost, oc
  hmr: false,
  enableSgsCommentary: true,
  sgsMaxComments: 8,
  operator: false,
  // Mixpanel Tokens
  mixpanelToken: 'ba63a2be40b747b17d02f1825251f6ca',
  planetsportMixpanelToken: '99ca2f84f9c7d9a4dc20e611b925bc9e',
  talksportMixpanelToken: '92e4e3b891251eb4e52a5fb2a24b152f',
  fitzdaresMixpanelToken: 'ed4572d56f4576fd22899e6e2e252d93',
  dreamteamMixpanelToken: '5fed414cf77e996436e69f81e33f0fc4',
  rockthegoatMixpanelToken: '88bb52fbd4983216d7e210d52281483c',
  racingpostMixpanelToken: 'bd54659c88811818df3e19f7437d2499',
  oddscheckerMixpanelToken: '620198d339980499ff787d2a6747cd1e',
  bolavipMixpanelToken: 'a535e34de2b2c7106d03198c87cda3a8',
  whoscoredMixpanelToken: '0701d095c0246636a1ff237474cc9d60',
  greatgoalsMixpanelToken: '80a8f6e148c4dd76ccb63088a6db4ec4',
  attheracesMixpanelToken: '3bd585937f0e4acaa3d12b6cfa65d3a5',

  heatmap: true,
  heatmapSync: 60000,
  lobbyHelpIcon: true,

  contentfulSpaceId: 'd5ew525xskhe',
  contentfulSpaceAccessToken: '9NzEyVjK4x5gZHN9o_geLisMYMt6N34W-Rzxwhj7iMI',
  onboardingJourney: ['LOGIN', 'RESET_PASSWORD', 'SPLASH', 'CONTACT', 'SIGNUP', 'FORGOT_PASSWORD', 'LANDING' ], // 'CONTACT', 'SIGNUP', 'FORGOT_PASSWORD', 'LANDING'
  currencyId: 1,
};
