import { Component, OnInit, Input } from '@angular/core';
import { AdminApiService } from 'projects/admin/src/api-service/api.service';
import moment from 'moment';

@Component({
  selector: 'engagement',
  templateUrl: './engagement.component.html',
})
export class EngagementComponent implements OnInit {

  @Input('isAdmin') isAdmin: boolean;

  source: string = '';
  medium: string = '';
  campaign: string = '';
  affiliateId: number;
  startDate: string = '';

  defaultAffiliateOption: any;

  affiliateOption: any;

  defaultUTMOption = {
    source: '',
    medium: '',
    campaign: ''
  };
  
  utmOption = {
    source: '',
    medium: '',
    campaign: ''
  };

  startDateOption = {
    name: 'Yesterday',
    days: 1,
  }

  startDateOptions: any[] = [
    {
      name: 'Yesterday',
      days: 1,
    },
    {
      name: 'Last Week',
      days: 7,
    },
    {
      name: 'Last Month',
      days: 30,
    }
  ]

  utms: any[];
  affiliates: any[];
  eventCounts: any[];

  acquisitionFunnel = {
    micrositeLanded: 0,
    micrositeSignUp: 0,
    registrationLanded: 0,
    registrationStarted: 0,
    kycAttempted: 0,
    registrationComplete: 0
  }

  retentionFunnel = {
    appLoaded: 0,
    ticketsCreated: 0,
    ticketsPurchased: 0,
    playersPurchased: 0
  }

  maxAcquisitionValue: number;
  maxRetentionValue: number;
  
  constructor(
    private apiService: AdminApiService,
  ) { }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.getAffiliates();
    }
    this.getUTMs();
    this.changeDate();
  }

  getUTMs() {
    this.source = '';
    this.medium = '';
    this.campaign = '';
    this.apiService.getUTMs(this.affiliateId).subscribe(res => {
      this.utms = res['data'];
    })
  }

  getAffiliates() {
    this.apiService.getAffiliates().subscribe(res => {
      this.affiliates = res['data']['affiliates'];
    })
  }

  getEventCounts() {
    
    this.apiService.getEventCounts(this.source, this.medium, this.campaign, this.startDate, this.affiliateId).subscribe(res => {
      this.eventCounts = res['data'];

      this.buildAcquisionFunnel();
      this.buildRetentionFunnel();
    })
  }

  buildAcquisionFunnel() {
    this.acquisitionFunnel = {
      micrositeLanded: this.eventCounts.find(ec => ec.eventName === 'MicrositeLanded')?.count ?? 0,
      micrositeSignUp: this.eventCounts.find(ec => ec.eventName === 'MicrositeSignUp')?.count ?? 0,
      registrationLanded: this.eventCounts.find(ec => ec.eventName === 'RegistrationLanded')?.count ?? 0,
      registrationStarted: this.eventCounts.find(ec => ec.eventName === 'RegistrationStarted')?.count ?? 0,
      kycAttempted: this.eventCounts.find(ec => ec.eventName === 'KycAttempted')?.count ?? 0,
      registrationComplete: this.eventCounts.find(ec => ec.eventName === 'RegistrationComplete')?.count ?? 0,
    }

    this.maxAcquisitionValue = Math.max(this.acquisitionFunnel.micrositeLanded, this.acquisitionFunnel.registrationLanded);
  }

  buildRetentionFunnel() {
    this.retentionFunnel = {
      appLoaded: this.eventCounts.find(ec => ec.eventName === 'AppLoad')?.count ?? 0,
      ticketsCreated: this.eventCounts.find(ec => ec.eventName === 'TICKETS_CREATED')?.count ?? 0,
      ticketsPurchased: this.eventCounts.find(ec => ec.eventName === 'TICKETS_PURCHASED')?.count ?? 0,
      playersPurchased: this.eventCounts.find(ec => ec.eventName === 'PLAYERS_PURCHASED')?.count ?? 0,
    }

    this.maxRetentionValue = Math.max(this.retentionFunnel.appLoaded, this.retentionFunnel.ticketsCreated);
  }

  changeDate() {
    this.startDate = moment().subtract(this.startDateOption.days, 'days').format('YYYY-MM-DD');
    this.getEventCounts();
  }

  changeAffiliateOption() {
    this.affiliateId = this.affiliateOption?.id;
    this.getUTMs();
    this.getEventCounts();
  }

  changeUTMOption() {
    this.source = this.utmOption.source;
    this.medium = this.utmOption.medium;
    this.campaign = this.utmOption.campaign;
    this.getEventCounts();
  }

  getAcquisitionHeight(input) {
    if (input === 0) {
      return '0%';
    }
    return 100 * (input / this.maxAcquisitionValue) + '%';
  }

  getRetentionHeight(input) {
    if (input === 0) {
      return '0%';
    }
    return 100 * (input / this.maxRetentionValue) + '%';
  }

}