import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterList',
  pure: false
})

export class FilterListPipe implements PipeTransform {
  transform(value: any[], term: string) {
      if (!term) {
          return value;
      }
      return value.filter(el => el.firstName.toLowerCase().includes(term.toLocaleLowerCase()) ||
        el.lastName.toLowerCase().includes(term.toLocaleLowerCase())
      );
  }
}
