import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringLength'
})
export class StringLengthPipe implements PipeTransform {

  transform(value: string, limit?: any): any {
    const assingLimit = limit ? limit : 10;
    if (!value) {
      return null;
    } else if (value.length > assingLimit) {
      return value.substr(0, assingLimit) + ' ...';
    } else {
      return value;
    }

  }

}
