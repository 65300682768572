import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-picklist',
  templateUrl: './picklist.component.html',
  styleUrls: ['./picklist.component.scss']
})
export class PicklistComponent {
  @Input() source: any[];
  @Input() target: any[];
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() showFirstAndLastName = false;
  @Output() listUpdated = new EventEmitter();

  public sourceChecked: boolean;
  public targetChecked: boolean;
  public filterSourceTerm: string;
  public filterTargetTerm: string;

  constructor() {
  }

  selectSourceElement(participant) {
    this.sourceChecked = true;

    if (this.targetChecked) {
      this.target.forEach(el => el.checked = false);
    }

    // Check 'id' property as it does not exist on some data source, which we have but 'participantI'.;
    if (participant.id) {
      this.source.find(el => el.id === participant.id).checked = !participant.checked;
    } else {
      this.source.find(el => el.participantId === participant.participantId).checked = !participant.checked;
    }
  }

  selectTargetElement(participant) {
    this.targetChecked = true;

    if (this.sourceChecked) {
      this.source.forEach(el => el.checked = false);
    }

    if (participant.id) {
      this.target.find(el => el.id === participant.id).checked = !participant.checked;
    } else {
      this.target.find(el => el.participantId === participant.participantId).checked = !participant.checked;
    }
  }

  moveSelectedParticipantsFromSource() {
    const selectedItems = this.source.filter(item => item.checked);

    if (selectedItems.length) {
      this.target.push(...selectedItems);
      this.source = this.source.filter(item => !item.checked);
      this.target.forEach(el => el.checked = false);
      this.onListUpdate();
    }
  }

  moveAllParticipantsFromSource() {
    this.target.push(...this.source);
    this.source = [];
  }

  moveSelectedParticipantsFromTarget() {
    const selectedItems = this.target.filter(item => item.checked);
    if (selectedItems.length) {
      this.source.push(...selectedItems);
      this.target = this.target.filter(item => !item.checked);
      this.source.forEach(el => el.checked = false);
      this.onListUpdate();
    }
  }

  moveAllParticipantsFromTarget() {
    this.source.push(...this.target);
    this.target = [];
    this.onListUpdate();
  }

  onListUpdate() {
    const data = { source: this.source, target: this.target };
    // Remove the 'checked' property  and return list sources.
    data.source.forEach(el => delete el.checked);
    data.target.forEach(el => delete el.checked);
    return this.listUpdated.emit(data);
  }

}
