import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appSticky]'
})
export class StickyDirective {
  public elementPosition: number;
  public elementBoundingRect: any;
  @Input() marginTop = 0;

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(){
    this.elementPosition = this.elementRef.nativeElement.offsetTop;
    this.elementBoundingRect = this.elementRef.nativeElement.getBoundingClientRect();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.elementRef.nativeElement.classList.add('sticky_fixed');
      this.elementRef.nativeElement.style.width = this.elementBoundingRect.width + 'px';
    } else {
      this.elementRef.nativeElement.classList.remove('sticky_fixed');
    }
  }
}
