import { Injectable } from '@angular/core';
import { teamAbbreviations, teamTranslations, wcTeamAbbreviations, sgsTeamAbbreviations, sgsBasketballTeamAbbreviations, snlTeamsAbbreviations } from './abbreviations';

@Injectable({
  providedIn: 'root'
})
export class AbbreviationService {

  abbreviateTeamName(name: string): string {
    let keys = Object.keys(teamAbbreviations.properties)
    if (keys.includes(name)) return teamAbbreviations.properties[name];
    else return '';
  }

  abbreviateWCTeamName(name: string): string {
    let keys = Object.keys(wcTeamAbbreviations.properties)
    if(keys.includes(name)) return wcTeamAbbreviations.properties[name];
    else return ''
  }

  abbreviateSgsTeamName(name: string): string {
    let keys = Object.keys(sgsTeamAbbreviations.properties)
    if (keys.includes(name)) return sgsTeamAbbreviations.properties[name];
    else return '';
  }

  abbreviateSgsBasketballTeamName(name: string): string {
    let keys = Object.keys(sgsBasketballTeamAbbreviations.properties)
    if (keys.includes(name)) return sgsBasketballTeamAbbreviations.properties[name];
    else return '';
  }

  getTeamAbbreviation(teamName: string) {
    return this.abbreviateTeamName(teamName);
  }

  getTranslations(teamName: string) {
    let keys = Object.keys(teamTranslations.properties)
    if(keys.includes(teamName)) return teamTranslations.properties[teamName]
    else return teamName
  }

  abbreviateSNLTeamName(name: string): string {
    let keys = Object.keys(snlTeamsAbbreviations.properties)
    if (keys.includes(name)) return snlTeamsAbbreviations.properties[name];
    else return name;
  }

}
