import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, take, switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'projects/onboarding/src/lib/login/login.service';
import { ApiService } from 'projects/player/src/api-service/api.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(public authService: AuthService, private router: Router, private loginService: LoginService, private spinner: NgxSpinnerService, private apiService: ApiService,) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }

    if (this.authService.isTokenExpired()) {
      this.loginService.logoutUser();
      this.apiService.updateWalletOnAction(null)
    }

    return next.handle(request).pipe(catchError(error => {
      // console.log('access token error is', error);
      if(error instanceof HttpErrorResponse) this.spinner.hide();
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));

  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'access-token': `${token}`,
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if(request.url.includes("/v2/players/login")){
      // this.isRefreshing = false;
      return next.handle(request)
    }
    // console.log('in handle 401 error method');
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          // console.log('tokens', token);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token['data']['newAccessToken']);
          return next.handle(this.addToken(request, token['data']['newAccessToken']));
        }),
        catchError(e => {
          if (e instanceof HttpErrorResponse && e.status === 422) {
            this.authService.doLogoutUser();
            return EMPTY;
          } else {
            return throwError(e);
          }
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
