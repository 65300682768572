import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FilterListPipe } from '../pipes/filterlist.pipe';
import { FilterOptionsPipe } from '../pipes/filterOptions.pipe';

import { RouterModule } from '@angular/router';
import { UtilsService } from '../services';
import { OutsideClickDirective, StickyDirective, TwoDecimalPlaceDirective } from '../directives';
import { RootModalModule } from './root-modal/root-modal.module';
import {
  DropdownSelectComponent,
  PicklistComponent,
  BreadcrumbsComponent,
  LeaderboardUiComponent,
  GameDetailsComponent,
  GameTicketsComponent,
  EngagementComponent,
} from '../components';
import { FooterModule } from './footer/footer.module';
import { LastNamePipe } from '../pipes/lastname.pipe';
import { StringLengthPipe } from '../pipes/string-length.pipe';
import { StringFilter } from '../pipes/stringFilter.pipe';
import { StringtodatePipe } from '../pipes/stringtodate.pipe';
import { TimeCounterPipe } from '../pipes/timecounter.pipe';

import { TranslateModule } from '@ngx-translate/core';
import { FsbFooterModule } from './fsb-footer/footer.module';
import { SnakesGridComponent } from 'projects/player/src/app/player-dashboard/picker/snakes-and-ladders-picker/snakes-grid/snakes-grid.component';
import { NudgeComponent } from 'projects/player/src/app/player-dashboard/picker/snakes-and-ladders-picker/nudge/nudge.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    RootModalModule, 
    FooterModule,
    TranslateModule
  ],
  providers: [UtilsService],
  declarations: [
    PicklistComponent,
    FilterListPipe,
    DropdownSelectComponent,
    EngagementComponent,
    FilterOptionsPipe,
    BreadcrumbsComponent,
    GameDetailsComponent,
    GameTicketsComponent,
    StickyDirective,
    LeaderboardUiComponent,
    OutsideClickDirective,
    TwoDecimalPlaceDirective,
    LastNamePipe,
    StringLengthPipe,
    StringFilter,
    StringtodatePipe,
    TimeCounterPipe,
    SnakesGridComponent,
    NudgeComponent
  ],
  exports: [
    PicklistComponent,
    FilterListPipe,
    LastNamePipe,
    StringLengthPipe,
    StringFilter,
    StringtodatePipe,
    TimeCounterPipe,
    DropdownSelectComponent,
    EngagementComponent,
    FilterOptionsPipe,
    BreadcrumbsComponent,
    GameDetailsComponent,
    GameTicketsComponent,
    StickyDirective,
    LeaderboardUiComponent,
    OutsideClickDirective,
    TwoDecimalPlaceDirective,
    RootModalModule, 
    FooterModule,
    TranslateModule,
    FsbFooterModule,
    SnakesGridComponent,
    NudgeComponent,
  ],
})


export class SharedModule {
}
