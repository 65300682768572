import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from 'projects/fantasy-shared/src/guard';

const routes: Routes = [
    // Player module -- ROLEID 2
    {
        path: '',
        loadChildren: () => import('projects/player/src/app/player-dashboard/player-dashboard.module')
            .then(m => m.PlayerDashboardModule),
            //  canActivate: [RoleGuardService], data: { expectedRole: [2] }
    },

    // Admin module -- ROLEID 1
    {
        path: '',
        loadChildren: () => import('projects/admin/src/app/admin-panel/admin-panel.module')
            .then(m => m.AdminPanelModule), canActivate: [RoleGuardService], data: { expectedRole: [1] }
    },
    { 
        path: 'partner-reporting', 
        loadChildren: () => import('projects/admin/src/app/reporting-panel/reporting-panel.module')
            .then(m => m.ReportingPanelModule), canActivate: [RoleGuardService], data: { expectedRole: [4] },
    },    
    // Onboarding Module -- ROLEID 3
    {
        path: '',
        loadChildren: () => import('projects/onboarding/src/lib/onboarding.module').then(m => m.OnboardingModule), 
        // canActivate: [RoleGuardService], data: { expectedRole: [3] }
    },
    // Error
    {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule],
})

export class AppRoutingModule { }
