import { HostListener, AfterViewInit } from '@angular/core';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { style } from '@angular/animations';

@Component({
  selector: 'app-leaderboard-ui',
  templateUrl: './leaderboard-ui.component.html',
  styleUrls: ['./leaderboard-ui.component.scss']
})

export class LeaderboardUiComponent implements OnInit, OnChanges {
  @Input() shouldAnimate = true; // Set this to false to disable animation.
  @Input() liveRoundParticipants = [];
  @Input() leaderboardData = [];
  @Input() favouritePlayerIds = [];
  @Input() favouriteTeams = [];
  @Input() favTicketIds = [];
  @Input() uniqueField: string;
  @Input() leaderboardType: string;
  @Input() searchInput: string;
  @Output() goToTicket = new EventEmitter();
  @Input() leaderBoardName: string; // This should be unique for each leaderboard as it will be used to animated leaderboard
  @Input() togglePotentialPointsAndWinnings;
  @Input() playerTicketIds = [];
  @Output() setFav = new EventEmitter();
  @Output() unsetFav = new EventEmitter();

  playerId: number;

  public playerRankings = [];
  public scrolling = false;

  constructor() { 
    this.playerId = parseInt(localStorage.getItem("PLAYER_ID")) || 0;
  }

  ngOnInit(): void {
    this.playerRankings = this.leaderboardData;
    if(this.leaderboardType === 'masters'){
      this.playerRankings = this.sortByOrder(this.playerRankings);
    }
    let scrollTimer = null;
    document.addEventListener('scroll', () => {
      this.scrolling = true;
      if (scrollTimer !== null) {
        clearTimeout(scrollTimer);
      }
      scrollTimer = setTimeout(() =>  this.scrolling = false, 1000);
    });
  }

    ngOnChanges(changes: SimpleChanges) {
      if (changes.leaderboardData && changes.leaderboardData.currentValue && changes.leaderboardData.previousValue) {
        if (!this.playerRankings.length) {
          this.playerRankings = changes.leaderboardData.currentValue;
          return;
        }

        if (this.searchInput) {
          this.playerRankings = changes.leaderboardData.currentValue;
        } else {
          const hasFocus = document.hasFocus();

          /* Animation will not happen if window is out of focus.
          /* This is to avoid bug in Chrome where elements Get out of place.
          */
          hasFocus && !this.scrolling && this.shouldAnimate ? this.animateLeaderboard(changes.leaderboardData.currentValue)
                  : this.playerRankings = changes.leaderboardData.currentValue;
        }
      }
  }

  sortByOrder(array: any[]): any[] {
    if (!array || array.length < 1) {
      return [];
    }
    array = array.sort((a, b) => a.order - b.order);
    const positiveElemnts = array.filter(it => it.position >= 1);
    const nonPositiveElements = array.filter(it => it.position < 1);
    return [...positiveElemnts, ...nonPositiveElements];
  }

  animateLeaderboard(currentLeaderboard) {
    this.playerRankings.forEach((user) => {
      const previousPost = document.getElementById(`player_item_${user[this.uniqueField]}_${this.leaderBoardName}`).getBoundingClientRect();
      user['previousPosition'] = previousPost;
    });

    currentLeaderboard.forEach((newUser) => {
      const oldUser = this.leaderboardType === 'masters' ? this.playerRankings.find(user => user.id === newUser.id)
        : this.playerRankings.find(user => user.ticketId === newUser.ticketId);
      const index = this.playerRankings.findIndex(el => el[this.uniqueField] === newUser[this.uniqueField]);
      if (oldUser) {
        const oldDomEL = document.getElementById(`player_item_${oldUser[this.uniqueField]}_${this.leaderBoardName}`);
        const currentDomEL = document.getElementById(`player_item_${newUser[this.uniqueField]}_${this.leaderBoardName}`);

        if (this.leaderboardType === 'masters' || this.leaderboardType === 'sfg') {
          oldDomEL.querySelector('.team_name').innerHTML = `${newUser.firstName} ${newUser.lastName}`;
        }

        if (this.leaderboardType === 'competition') {
          oldDomEL.querySelector('.team_name').innerHTML = `${newUser.teamName}`;
        }

        oldDomEL.querySelector('.order').innerHTML = newUser.displayPosition || '-';

        newUser[this.uniqueField] < oldUser[this.uniqueField] ? oldDomEL.querySelector('.order').innerHTML = '↑'
          : oldDomEL.querySelector('.order').innerHTML = '↓';

        if (newUser[this.uniqueField] === oldUser[this.uniqueField]) {
          oldDomEL.querySelector('.order').innerHTML = newUser.displayPosition || '-';
        }

        if (this.leaderboardType === 'sfg') {
          oldDomEL.querySelector('.team_name').innerHTML = `${newUser.displayName}`;
          oldDomEL.querySelector('.order').innerHTML = newUser.displayRank || '-';
        }

        if (this.leaderboardType === 'horseRacing') {
          oldDomEL.querySelector('.team_name').innerHTML = `${newUser.displayName}`;
          oldDomEL.querySelector('.order').innerHTML = newUser.displayRank;
        }


        if (newUser[this.uniqueField] !== oldUser[this.uniqueField]) {
          oldDomEL.classList.add('animating');
          const dy = currentDomEL.getBoundingClientRect().top - oldUser.previousPosition.top;
          oldDomEL.style.transform = `translateY(${dy}px)`;
          oldDomEL.style.transitionDuration = '2s';
        }

        oldDomEL.ontransitionend = () => {
          oldDomEL.classList.remove('animating');
          this.playerRankings[index] = newUser;
        };
      }
    });
  }


  gotoTicketItem(ticket) {
    this.goToTicket.emit(ticket);
  }

  getTicketName(number) {
    const alphabets = [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
      'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    const idx = Number(number);
    if (idx >= 0 && idx <= 26) {
      return alphabets[idx - 1];
    } else {
      return number;
    }
  }

  showFlag(player) {
    if (!player) { return ''; }

    const withdrawnFlag = player.withdrawn && player.withdrawn.length > 0 ? player.withdrawn : '';
    const cutFlag = player.madeCut && player.madeCut === 'no' ? 'CUT' : '';

    if (withdrawnFlag) {
      return withdrawnFlag;
    } else if (cutFlag) {
      return cutFlag;
    } else {
      return false;
    }
  }

  joinParticipantsNames(selections) {
    if (!selections || selections.length === 0) { return ''; }
    let res = selections[selections.length-1];
    let sel = [];
    selections.map((s,idx) => idx !== selections.length-1 && sel.push(s));
    sel = sel.sort((a,b) => a[1] > b[1] ? 1 : -1)
    sel[selections.length-1] = res;
    let selectionString = '';
    sel.map((it, idx) => {
      if(idx === 5) selectionString +=  ' | ' + this.getPlayerName(it)
      else if(idx === 4) selectionString += this.getPlayerName(it)
      else selectionString += this.getPlayerName(it) + ', '
    })
    return selectionString.split(" | ")
  }

  getPlayerName(nameArr){
    if(this.liveRoundParticipants){
      let tempArr = this.liveRoundParticipants.filter(p => p.lastName === nameArr[1])
      if(tempArr.length > 1){
        return nameArr[0][0] + '. ' + nameArr[1];
      }else{
        return nameArr[1];
      }
    }
  }

  showScore(score: number) {
    if (!score) { return 0; }
    return Number(score);
  }

  onSetFavouritePlayerCompetition(ticketId: string) {
    this.setFav.emit(ticketId);
  }

  onUnSetFavouritePlayerCompetition(ticketId: string) {
    this.unsetFav.emit(ticketId);
  }

  onSetFavouritePlayerMasters(playerId: string) {
    this.setFav.emit(playerId);
  }

  onUnSetFavouritePlayerMasters(playerId: string) {
    this.unsetFav.emit(playerId);
  }

  setUnsetFavHorse(ticketId: string) {
    window.document.querySelector(".tabs_row").scrollIntoView()
    this.setFav.emit(ticketId);
  }

}
