import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFilter'
})

export class StringFilter implements PipeTransform {
  transform(data: any, search?: string, propertyName?: string): any {
      if (search === undefined) {
          return data;
      } else {
          const filteredData = data.filter(obj => String(obj[propertyName]).toLowerCase().includes(search.toLowerCase()));
          return filteredData;
      }
  }
}
