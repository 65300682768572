<!-- Nudge wrapper -->
<div class="nudge_wrapper animate__animated animate__fadeIn" [ngClass]="'over_'+overBoxNumber">
    <div class="header">
        <div class="small_header">{{nudgeTitle}}</div>
        <div class="_title">HOW MANY RUNS WILL BE IN THE {{(startingOver + overBoxNumber)}}{{getSuffix()}} OVER</div>
    </div>
    <div class="action_btns_wrapper">
        <button class="cancel action_btn" (click)="cancel()">CANCEL</button>
        <div class="plus_minus">
            <button class="minus_btn nudge_btn" (click)="subtractNudgeValue()">
                <span class="icon ng-icon-minus"></span>
            </button>
            <div class="counter">{{nudgeValue}}</div>
            <button class="plus_btn nudge_btn" (click)="addNudgeValue()">
                <span class="icon ng-icon-plus-alt"></span>
            </button>
        </div>
        <button class="confirm action_btn" (click)="confirmNudgeValue()">CONFIRM</button>
    </div>
    <span class="nudge_pointer" [ngClass]="'over_'+overBoxNumber"></span>
</div>
<!-- #Nudge wrapper -->