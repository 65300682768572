<div class="relative z-0 flex flex-1 overflow-hidden justify-center items-center flex-col m-8">
  <div class="flex flex-col justify-center items-center">    
    <h1 class="text-lg my-8">Filters</h1>
    <div class="flex w-96 justify-between items-center mb-8">
      <label for="startDateOption">Start Date:</label>
      <select id="startDateOption" name="startDateOption" [(ngModel)]="startDateOption" (change)="changeDate()"
        class="block rounded-md border border-gray-300 bg-white py-2 p-2 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm w-1/2" >
        <ng-container *ngFor="let option of startDateOptions">
          <option [ngValue]="option">{{ option.name }}</option>  
        </ng-container>
      </select>
    </div>
    <div class="flex w-96 justify-between items-center mb-8" *ngIf="isAdmin">
      <label for="affiliateOption">Affiliates:</label>
      <select id="affiliateOption" name="affiliateOption" [(ngModel)]="affiliateOption" (change)="changeAffiliateOption()"
        class="block rounded-md border border-gray-300 bg-white py-2 p-2 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm w-1/2" >
        <option [ngValue]="defaultAffiliateOption">All</option>
        <ng-container *ngFor="let option of affiliates">
          <option [ngValue]="option">{{ option.name }}</option>  
        </ng-container>
      </select>
    </div>
    <div class="flex w-96 justify-between items-center mb-8">
      <label for="utmOption">UTMs:</label>
      <select id="utmOption" name="utmOption" [(ngModel)]="utmOption" (change)="changeUTMOption()"
        class="block rounded-md border border-gray-300 bg-white py-2 p-2 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm w-1/2" >
        <option [ngValue]="defaultUTMOption">All</option>
        <ng-container *ngFor="let option of utms">
          <option [ngValue]="option">{{ option.source }} / {{ option.medium }} / {{ option.campaign }}</option>  
        </ng-container>
      </select>
    </div>
    <h1 class="text-lg my-8">Acquisition Funnel</h1>
    <div class="flex w-full items-end" style="height: 13rem;">
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getAcquisitionHeight(acquisitionFunnel.micrositeLanded)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getAcquisitionHeight(acquisitionFunnel.micrositeSignUp)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getAcquisitionHeight(acquisitionFunnel.registrationLanded)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getAcquisitionHeight(acquisitionFunnel.registrationStarted)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getAcquisitionHeight(acquisitionFunnel.kycAttempted)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getAcquisitionHeight(acquisitionFunnel.registrationComplete)}"></div>
    </div>
    <div class="flex w-full">
      <div class="flex-1 text-center">Microsite<br/>Landed:<div class="font-semibold">{{acquisitionFunnel.micrositeLanded}}</div></div>
      <div class="flex-1 text-center">Microsite<br/>Sign Up:<div class="font-semibold">{{acquisitionFunnel.micrositeSignUp}}</div></div>
      <div class="flex-1 text-center">Registration Landed:<div class="font-semibold">{{acquisitionFunnel.registrationLanded}}</div></div>
      <div class="flex-1 text-center">Registration Started:<div class="font-semibold">{{acquisitionFunnel.registrationStarted}}</div></div>
      <div class="flex-1 text-center">KYC<br/>Attempted:<div class="font-semibold">{{acquisitionFunnel.kycAttempted}}</div></div>
      <div class="flex-1 text-center">Registration Complete:<div class="font-semibold">{{acquisitionFunnel.registrationComplete}}</div></div>
    </div>
    <h1 class="text-lg my-8">Retention Funnel</h1>
    <div class="flex w-full items-end" style="height: 13rem;">
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getRetentionHeight(retentionFunnel.appLoaded)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getRetentionHeight(retentionFunnel.ticketsCreated)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getRetentionHeight(retentionFunnel.ticketsPurchased)}"></div>
      <div class="flex justify-center items-center text-white border-slate-300 border-solid border-2 font-semibold flex-1 bg-indigo-700" [ngStyle]="{height: getRetentionHeight(retentionFunnel.playersPurchased)}"></div>
    </div>
    <div class="flex w-full">
      <div class="flex-1 text-center">App<br/>Loaded:<div class="font-semibold">{{retentionFunnel.appLoaded}}</div></div>
      <div class="flex-1 text-center">Tickets<br/>Created:<div class="font-semibold">{{retentionFunnel.ticketsCreated}}</div></div>
      <div class="flex-1 text-center">Tickets<br/>Purchased:<div class="font-semibold">{{retentionFunnel.ticketsPurchased}}</div></div>
      <div class="flex-1 text-center">Players<br/>Purchased:<div class="font-semibold">{{retentionFunnel.playersPurchased}}</div></div>
    </div>
  </div>
</div>
