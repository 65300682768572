import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})

export class DropdownSelectComponent implements OnChanges {
  public showDropdown = false;
  public isObjectArray = false;
  public optionsFilterString = '';
  public selectedOptions: any[] = [];
  public changesDone = 0;
  @Input() options: any[];
  @Input() selectMultiple = false;
  @Input() displayKey: string;
  @Input() uniqueKey: string;
  @Input() selectedItems = [];
  @Input() closeOnSelect = false;
  @Output() optionsUpdated = new EventEmitter();

  /**
   * Use the @selectMultiple to allow for single or multiple selects.
   * @isArrayObject Check if the array supplied is an array of objects or a simple array then show the appropriate UI element.
   * The @displayKey is the name of the object to be displayed in the UI, if array is array of Objects.
   */

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && !changes.options.firstChange && changes.options.currentValue.length) {
      this.changesDone += 1;
      this.isObjectArray = this.options.some(el => typeof el === 'object');
      if (!this.isObjectArray) {
        this.displayKey = 'value';
        this.options = this.options.map((el, index) => ({ value: el, checked: false, item_id: index }));
      } else {
        this.options.forEach((el, index) => {el.checked = false; el.item_id = index; });
      }
    }

    if (changes.selectedItems && changes.selectedItems['currentValue'] && !changes.selectedItems['previousValue']) {
      this.changesDone += 1;
    }

    if (this.changesDone === 2) {
      this.selectedOptions = [];
      this.selectedItems.forEach((item) => {
        let opt;
        if (this.isObjectArray) {
          opt = this.options.find(el => el[this.uniqueKey] === item[this.uniqueKey]);
          opt.checked = true;
        } else {
          opt = this.options.find(el => el.value === item);
          opt.checked = true;
        }
        this.selectedOptions.push(opt);
      });
      this.changesDone = 0;
    }
  }

  selectOption(option) {
    if (this.selectMultiple) {
      this.options.find(el => el.item_id === option.item_id).checked = !option.checked;
      this.selectedOptions = this.options.filter(el => el.checked);
    } else {
      this.selectedOptions = [];
      this.options.forEach(el => el.checked = false);
      this.options.find(el => el.item_id === option.item_id).checked = !option.checked;
      this.selectedOptions = this.options.filter(el => el.checked);
    }
    this.closeOnSelect ? this.showDropdown = false : this.showDropdown = true;
    this.onOptionsUpdated();
  }

  onOptionsUpdated() {
    return this.optionsUpdated.emit(this.selectedOptions);
  }

}
