import { Pipe, PipeTransform } from '@angular/core';
import { interval } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
@Pipe({
  name: 'timecounter'
})
export class TimeCounterPipe implements PipeTransform {
    _diff;
    _days;
    _hours;
    _minutes;
    _seconds;
    d: string;
    h: string;
    m: string;
    s: string;
 // list.roundEndTime
  transform(endTime: any, type: string) {
    if (endTime) {
  const data = interval(1000).pipe(
    map((x) => {
      this._diff = Date.parse(endTime) - Date.parse(new Date().toString());
      this._days =  Math.floor(this._diff / (1000 * 60 * 60 * 24));
      this._hours = Math.floor((this._diff / (1000 * 60 * 60)) % 24);
      this._minutes = Math.floor((this._diff / 1000 / 60) % 60);
      this._seconds = Math.floor((this._diff / 1000) % 60);


      if (this._days >= 1) {
        this._days > 1 ? this.d = this._days + ' days' : this.d = this._days + ' day';
      } else {
        this.d  = '';
      }

      if (this._days < 1 && this._hours >= 1) {
        this._hours === 1 ? this.h = this._hours + ' hr' : this.h = this._hours + ' hrs';
      } else {
        this.h  = '';
      }

      if (this._days < 1 && this._hours < 1 && this._minutes >= 10) {
        this.m = this._minutes + ' min';
      } else {
        this.m  = '';
      }

      if (this._days < 1 && this._hours < 1 && this._minutes < 10) {
        this.s = '0' + this._minutes + ':' + (this._seconds <= 9? `0${this._seconds}`: this._seconds);
      } else {
        this.s  = '';
      }

      if (this._diff > 0) {
        return this.d + this.h + this.m + this.s;
      } else {
        if (type === 'early')
          return 'Open';
        else if (type === 'btn') 
          return '';
        else
          return 'Closed';
      }
    }));
        return data;
      } else {
        return null;
      }
    }
}