<div class="game_details" [ngClass]="{'dark': dark, 'border_top': borderTop, 'border_bottom': borderBottom, 'for_desktop': forPicker && isDesktop}">
    <div class="price_and_time">

        <div class="price_and_time_item" *ngIf="getPicksCloseTimeFromRoundActions() as action">
            <span class="head" [ngClass]="{'dark': dark}" *ngIf="round?.state === 'EARLY'">Picks open</span>
            <span class="head" [ngClass]="{'dark': dark}" *ngIf="round?.state === 'OPEN'">Closes in</span>
            <span class="head" [ngClass]="{'dark': dark}" *ngIf="round?.state !== 'EARLY' && round?.state !== 'OPEN'">Game</span>
            <ng-container *ngIf="round?.state === 'EARLY' || round?.state === 'OPEN'">
                <span class="body timer" *ngIf="action['scheduled'] | timecounter:'open' | async as timer "
                    [ngClass]="{'danger': (checkCountDownTime(action['scheduled']) < 10), 
                    'warning': (checkCountDownTime(action['scheduled']) >= 10 && checkCountDownTime(action['scheduled']) <= 59),
                    'success': (checkCountDownTime(action['scheduled']) > 59 && checkCountDownTime(action['scheduled']) < 1440),
                    'grey': (checkCountDownTime(action['scheduled']) >= 1440)}">
                    <span *ngIf="checkCountDownTime(action['scheduled']) <= 1440" class="icon ng-icon-clock-timer"></span>
                    {{timer === 'Picks closed' ? 'Closed' : timer}}
                </span>
            </ng-container>
            <span class="body red" *ngIf="round?.state === 'LIVE'">LIVE</span>
            <span class="body" [ngClass]="{'dark': dark}" *ngIf="round?.state === 'RESULTED'">FINISHED</span>
            <span class="body" [ngClass]="{'dark': dark}" *ngIf="round?.state === 'CANCELLED'">CANCELLED</span>
        </div>

        <div class="price_and_time_item">
            <span class="head" [ngClass]="{'dark': dark}">Prizes</span>
            <span class="body alpha_currency_container" [ngClass]="{'dark': dark}">
                <ng-container *ngIf="currencyId === 1">£</ng-container>
                <ng-container *ngIf="currencyId === 2"><span class="icon ng-icon-coin" style="display: flex;"></span></ng-container>
                {{ getDataFromRoundPools(true) | number: '1.0-0' }}</span>
        </div>

        <div class="price_and_time_item" *ngIf="showEntries">
            <span class="head" [ngClass]="{'dark': dark}">Entries</span>
            <span class="body" [ngClass]="{'dark': dark}">{{ getDataFromRoundPools(false) | number }}</span>
        </div>

        <div class="price_and_time_item" *ngIf="showPrice">
            <span class="head" [ngClass]="{'dark': dark}">{{ isRebuy() ? 'Rebuy' : 'Entry' }}</span>
            <span class="body alpha_currency_container" [ngClass]="{'dark': dark}">
                <ng-container *ngIf="currencyId === 1">£</ng-container>
                <ng-container *ngIf="currencyId === 2"><span class="icon ng-icon-coin" style="display: flex;"></span></ng-container>
                {{ getRoundTicketPrice() }}</span>
        </div>

        <div class="price_and_time_item" *ngIf="showSurvivors">
            <span class="head" [ngClass]="{'dark': dark}">Survivors</span>
            <span class="body" [ngClass]="{'dark': dark}">{{ survivorCount | number }}</span>
        </div>

        <div class="price_and_time_item" *ngIf="showRoundNumber">
            <span class="head" [ngClass]="{'dark': dark}">Round</span>
            <span class="body" [ngClass]="{'dark': dark}">{{ round.roundNumber }}</span>
        </div>
    </div>
</div>