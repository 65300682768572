import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringtodate'
})
export class StringtodatePipe implements PipeTransform {

  transform(value: any, args?: any): Date {
    const reggie = /(\d{4})-(\d{2})-(\d{2})/;

    // console.log('editplayerdob', value);
    if (value && args) {
      const dateObject = new Date(value);
      dateObject.setHours( dateObject.getHours());
      dateObject.setMinutes( dateObject.getMinutes() + 2 );
     // console.log('editplayerdobar', dateObject);
      return dateObject;
    } else if (value) {
        const dateObject = new Date(value);
        // console.log('editplayerdob', dateObject);
        return dateObject;
    } else {
        return null;
      }
    }
}
