<!-- Picklist component -->
<div class="picklist_container">
    <div class="source_container">
        <h4>{{sourceHeader}}</h4>
        <div class="picklist_filter">
            <input type="text" name="" id="" [(ngModel)]="filterSourceTerm" (keydown.enter)="$event.preventDefault()">
            <span class="icon ng-icon-search"></span>
            <span class="close_icon" *ngIf="filterSourceTerm" (click)="filterSourceTerm = ''">x</span>
        </div>

        <div class="list_items">
            <div *ngFor="let participant of source | filterList: filterSourceTerm; let i=index" class="list_item" (click)="selectSourceElement(participant)">
                {{showFirstAndLastName ? participant.firstName + ' ' + participant.lastName : participant.firstName }}
                <div class="item_checkbox multiselect" (click)="$event.stopPropagation()"> 
                    <input [(ngModel)]="participant.checked" type="checkbox" id="checkbox-source-{{i}}"/>
                    <label for="checkbox-source-{{i}}"></label>
                </div>
            </div>
        </div>
    </div>

    <div class="control_buttons_container">
        <span (click)="moveSelectedParticipantsFromSource()">></span>
        <span (click)="moveAllParticipantsFromSource()">>></span>
        <span (click)="moveSelectedParticipantsFromTarget()"><</span>
        <span (click)="moveAllParticipantsFromTarget()"><<</span>
    </div>

    <div class="target_container">
        <h4>{{targetHeader}}</h4>
        <div class="picklist_filter">
            <input type="text" name="" id="" [(ngModel)]="filterTargetTerm" (keydown.enter)="$event.preventDefault()">
            <span class="icon ng-icon-search"></span>
            <span class="close_icon" *ngIf="filterTargetTerm" (click)="filterTargetTerm = ''">x</span>
        </div>

        <div class="list_items">
            <div *ngFor="let participant of target | filterList: filterTargetTerm; let j=index" class="list_item" (click)="selectTargetElement(participant)">
                {{showFirstAndLastName ? participant.firstName + ' ' + participant.lastName : participant.firstName }}
                <div class="item_checkbox multiselect" (click)="$event.stopPropagation()"> 
                    <input [(ngModel)]="participant.checked" type="checkbox" id="checkbox-target-{{j}}"/>
                    <label for="checkbox-target-{{j}}"></label>
                </div>
            </div>
        </div>
        
    </div>
</div>
<!-- #Picklist component -->