<!-- breadcrumb -->
<ul class="breadcrumb_menu_list">
    <li *ngFor="let item of menuItems; let first=first; let last=last">
        <a [routerLink]='item.routerLink ? [item.routerLink] : null' [ngClass]="{'is_active': item.active}">
            {{item.label}}
            <span *ngIf="!last">            
                <span class="icon ng-icon-chevron-right"></span>
            </span>
        </a>
    </li>
</ul>
<!-- #breadcrumb -->
