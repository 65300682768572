import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterOptions',
  pure: false
})

export class FilterOptionsPipe implements PipeTransform {
  transform(value: any[], field: string, term: string) {
      if (!term) {
          return value;
      }
      return value.filter(el => el[field].toLowerCase().includes(term.toLocaleLowerCase()));
  }
}
