<router-outlet></router-outlet>
<ngx-spinner showSpinner="true" bdColor ="rgba(0, 0, 0, 0.8)" size ="medium" color ="#fff" type ="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white" > Loading... </p>
</ngx-spinner>
<div *ngIf="versionOutdated" class="version_outdated_container">
    <div class="version_outdated">
        <h1>Oops, you're out of date</h1>
        <p>Please refresh your browser to view the latest changes</p>
        <button (click)="refreshBrowser()">Refresh Browser</button>
    </div>
</div>
