<div class="main_grid_wrapper" id="mainGridWrapper">
    <div class="grid_items_wrapper">
        <div class="grid_and_payout_amount_wrapper">
            <div class="non_zero_grid_wrapper">
                <ng-container *ngFor="let grid of grids;">
                    <div class="grid_item" *ngIf="grid.position !== 0" [id]="'grid_item_'+grid.position" 
                        [ngClass]="{'selected_grid': grid.selectedGrid}" [class]="getSelectedPickClass(grid)">
                        <div class="grid_amount"
                            [ngClass]="{'finis_grid_amount': gridMarkerPosition == grid.position && state == 'RESULTED' }"
                            *ngIf="getGridPayoutAmounts(grid) > 0">
                            <span *ngIf="currencyId === 1" [ngClass]="{'selectedGridAmount': grid.selectedGrid}">
                                {{getGridPayoutAmounts(grid) | currency: 'GBP'}}</span>
                            <span *ngIf="currencyId === 2" [ngClass]="{'selectedGridAmount': grid.selectedGrid}">
                                <span
                                    class="icon ng-icon-coin currency_span"></span>{{getGridPayoutAmounts(grid) | number:'1.2-2' }}</span>
                            <ng-container *ngIf="grid.selectedGrid">
                                <!-- <span *ngFor="let pick of grid.picks; trackBy: trackByFn"
                                    class="pick_number animate__animated animate__heartBeat animate__delay-1s">
                                    {{pick.pickNumber}}{{pick.selectedObjectSuffix}} pick
                                </span> -->

                            </ng-container>
                        </div>
                        <span class="grid_index"
                            [ngClass]="{'finish_grid': gridMarkerPosition == grid.position && state == 'RESULTED' }">{{grid.position}}</span>
                        <span *ngIf="state === 'RESULTED' && grid.position == gridMarkerPosition"
                            class="icon ng-icon-star-solid star_icon_marker"></span>
                    </div>
                </ng-container>
            </div>
            <div class="start_grid" id="grid_item_{{grids[grids.length-1]?.position}}">
                <span class="grid_index">{{grids[grids.length-1]?.position}}</span>
                <div class="helmet_marker"></div>
            </div>
        </div>

        <div class="payout_amount_container">
            <div class="_amount" *ngFor="let amount of grids">
                <ng-container *ngIf="currencyId === 1">
                    {{getGridPayoutAmounts(amount) | currency: 'GBP':'symbol':'1.0-2'}}
                </ng-container>
                <ng-container *ngIf="currencyId === 2">
                    <span
                        class="icon ng-icon-coin currency_span"></span>{{getGridPayoutAmounts(amount) | number:'1.2-2' }}
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Potential winnings modal  -->
<root-modal [id]="'potential-winnings'" class="alt-modal">
    <div class="modal_wrapper_alt">
        <div class="main_content_wrapper">
            <div class="close_icon" (click)="closeModal('potential-winnings')">
                <span class="icon ng-icon-times"></span>
            </div>
            <div class="modal_content">
                <span class="icon ng-icon-exclamation-filled-alt info_icon"></span>
                <span class="content_header_text">Potential winnings</span>
                <p class="content_body_text">Winning amounts are based on the number of correct picks you make and the
                    stake you choose. The more correct picks you make, the more you win.
                </p>
                <button (click)="closeModal('jackpot-modal')" class="action_btn">GOT IT</button>
            </div>
        </div>
    </div>
</root-modal>
<!-- #Potential winnings modal  -->

<!-- Jackpot modal  -->
<root-modal [id]="'jackpot-modal'" class="alt-modal">
    <div class="modal_wrapper_alt">
        <div class="main_content_wrapper">
            <div class="close_icon" (click)="closeModal('jackpot-modal')">
                <span class="icon ng-icon-times"></span>
            </div>
            <div class="modal_content">
                <span class="icon ng-icon-exclamation-filled-alt info_icon"></span>
                <span class="content_header_text">Jackpot</span>
                <p class="content_body_text">Winning amounts are based on the number of correct picks you make and the
                    stake you choose. The more correct picks you make, the more you win.
                </p>
                <button (click)="closeModal('jackpot-modal')" class="action_btn">GOT IT</button>
            </div>
        </div>
    </div>
</root-modal>
<!-- #Jackpot  modal  -->

<!-- Game voided modal  -->
<root-modal [id]="'game-voided-modal'" class="alt-modal">
    <div class="modal_wrapper_alt">
        <div class="main_content_wrapper">
            <div class="close_icon" (click)="closeModal('game-voided-modal')">
                <span class="icon ng-icon-times"></span>
            </div>
            <div class="modal_content">
                <span class="icon ng-icon-exclamation-filled-alt info_icon"></span>
                <span class="content_header_text">Sorry, game voided</span>
                <p class="content_body_text">As this game didn’t last the full 4 overs it has been voided. All players
                    will be refunded their full stake.
                </p>
                <button (click)="closeModal('jackpot-modal')" class="action_btn primary">GOT IT</button>
            </div>
        </div>
    </div>
</root-modal>
<!-- #Game voided  modal  -->


<!-- Game round already live -->
<root-modal [id]="'game-live-modal'" class="alt-modal">
    <div class="modal_wrapper_alt">
        <div class="main_content_wrapper">
            <div class="close_icon cursor-pointer" (click)="closeModal('game-live-modal')">
                <span class="icon ng-icon-times"></span>
            </div>
            <div class="modal_content">
                <span class="icon ng-icon-exclamation-filled-alt info_icon"></span>
                <span class="content_header_text">Sorry, entries for this game are now closed </span>
                <button (click)="gotoNextOpenRound()" class="action_btn">ENTER NEXT GAME</button>
            </div>
        </div>
    </div>
</root-modal>

<!-- Game round already live -->