
import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { ModalService } from './root-modal.service';

@Component({
  selector: 'root-modal',
  templateUrl: './root-modal.component.html',
})

export class RootModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
      this.element = el.nativeElement;
      this.element.style.display = 'none';
  }

  ngOnInit(): void {
      if (!this.id) {
          console.error('modal must have an id');
          return;
      }

      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      document.body.appendChild(this.element);

      // close modal on background click
      this.element.addEventListener('click', el => {
          if (el.target.className === 'app_modal') {
              this.close();
          }
      });

      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
  }

  open(): void {
      this.element.style.display = 'block';
    //   document.body.classList.add('app_modal_open');
  }

  close(): void {
      this.element.style.display = 'none';
    //   document.body.classList.remove('app_modal_open');
  }
}