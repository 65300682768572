import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  ActivatedRoute,
  RouterStateSnapshot
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigConstants } from 'config-constants';
import { de } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
  getClientURL: any;
  affName: string;
  constructor(public router: Router, private activeRoute: ActivatedRoute) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    const returnUrl = state.url;
    const token = localStorage.getItem('JWT_TOKEN');
    if(token == 'undefined' || token == null) {
      localStorage.removeItem('PLAYER_ID');
      this.affName = localStorage.getItem('AFF_NAME');

      if (this.affName === 'null' || this.affName === null || this.affName === undefined) {
        if(returnUrl) this.router.navigate(['landing'], { queryParams: { returnUrl } })
      } else {
        if(expectedRole[0] === 1) {
          if(ConfigConstants.APP_AFFILIATES.includes('FTZ') && this.affName === 'fitzdares') this.router.navigate(['partner/fitzdares'])
          else if(ConfigConstants.APP_AFFILIATES.includes('TS') && this.affName === 'talksport') this.router.navigate(['partner/talksport'])
          else if(ConfigConstants.APP_AFFILIATES.includes('DT') && this.affName === 'dreamteam') this.router.navigate(['partner/dreamteam'])
          else if(ConfigConstants.APP_AFFILIATES.includes('RTG') && this.affName === 'rockthegoat') this.router.navigate(['partner/rockthegoat'])
          else if(ConfigConstants.APP_AFFILIATES.includes('RPOST') && this.affName === 'racingpost') this.router.navigate(['partner/racingpost'])
          else if(ConfigConstants.APP_AFFILIATES.includes('OC') && this.affName === 'oddschecker') this.router.navigate(['partner/oddschecker'])
          else if(ConfigConstants.APP_AFFILIATES.includes('BVIP') && this.affName === 'bolavip') this.router.navigate(['partner/bolavip'])
          else if(ConfigConstants.APP_AFFILIATES.includes('WS') && this.affName === 'whoscored') this.router.navigate(['partner/whoscored'])
        } else if (expectedRole[0] === 2) {
          this.router.navigate([''])
        }
      }
    }
    else {
      const tokenPayload = new JwtHelperService().decodeToken(token);
      if (!expectedRole.includes(tokenPayload.user.roleId)) {
        
        if(tokenPayload.user.roleId === 2) {
          if(ConfigConstants.PLATFORM_APPS.includes('')) this.router.navigate(['/'])
          else this.router.navigate(['landing'])
        } 
        else if (tokenPayload.user.roleId === 1) {
          if(ConfigConstants.PLATFORM_APPS.includes('admin')) this.router.navigate(['/'])
          else this.router.navigate(['landing'])
        }
      }
    }
       
    return true;
  }
}
