import { Component, Input } from '@angular/core';
import { ConfigConstants } from 'config-constants';
import moment from 'moment';
import { UtilsService } from '../../services';

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.scss'],
})

export class GameDetailsComponent {
  @Input() game: any;
  @Input() round: any;
  @Input() dark: boolean = false;
  @Input() borderTop: boolean = false;
  @Input() borderBottom: boolean = true;

  @Input() showEntries: boolean = true;
  @Input() showPrice: boolean = true;
  @Input() showSurvivors: boolean = false;
  @Input() showRoundNumber: boolean = false;
  @Input() forPicker: boolean = false;
  @Input() isDesktop: boolean = false;
  @Input() survivorCount: number = 0;
  @Input() eliminatedCount: number = 0;
  @Input() totalPrize: number = 0;
  @Input() totalCount: number = 0;
  currencyId = ConfigConstants.CURRENCY_ID;

  constructor(
    private utils: UtilsService
  ) { }

  getPotSizeFromGame() {
    return this.game?.potToShow ?? '0';
  }

  getPicksCloseTimeFromRoundActions() {
    if (this.round?.state === 'EARLY') {
      return this.round?.actions?.find(it => it['name'] === 'MOVE_EARLY_TO_OPEN') ?? '';
    } else {
      return this.round?.actions?.find(it => it['name'] === 'MOVE_OPEN_TO_LIVE') ?? '';
    }
  }

  checkCountDownTime(scheduledTime: string): number {
    const timeDiff = moment().diff(scheduledTime, 'minutes');
    return Math.abs(timeDiff)
  }

  getRoundTicketPrice() {
    if (!this.round || !this.round.fields) { return '0'; }
    let roundField;
    if (this.round.state === 'EARLY') {
      roundField = this.round.fields.find(it => it['name'] === 'earlyPrice' && it['group'] === 'ticketsPrice');
    } else {
      if (this.isRebuy()) {
        roundField = this.round.fields.find(it => it['name'] === 'rebuyPrice' && it['group'] === 'ticketsPrice');
      } else {
        roundField = this.round.fields.find(it => it['name'] === 'regularPrice' && it['group'] === 'ticketsPrice');
      }
    }
    return roundField?.value ?? '0';
  }

  getEntriesFromRoundFields() {
    const roundFields = this.round && this.round['fields'];
    const regularCount = roundFields?.find(it => it['name'] === 'regularCount' && it['group'] === 'entriesCount')?.value ?? 0;
    const earlyCount = roundFields?.find(it => it['name'] === 'earlyCount' && it['group'] === 'entriesCount')?.value ?? 0;
    const rebuyCount = roundFields?.find(it => it['name'] === 'rebuyCount' && it['group'] === 'entriesCount')?.value ?? 0;
    const bonusCount = roundFields?.find(it => it['name'] === 'bonusCount' && it['group'] === 'entriesCount')?.value ?? 0;
    return Number(regularCount) + Number(earlyCount) + Number(rebuyCount) + Number(bonusCount);
  }

  getRakedPotFromRoundFields() {
    const gameFields = this.game && this.game['fields'];
    const rakedPot = gameFields?.find(it => it['name'] === 'rakedPot' && it['group'] === 'pot')?.value ?? 0;
    return Number(rakedPot);
  }

  getDataFromRoundPools(pot: boolean = true) {
    if(['footballsurvivor', 'basketballsurvivor', 'icehockeysurvivor'].includes(this.utils.getGameTypeName(this.game))){
      if(pot) return this.totalPrize
      else if (this.round.roundNumber === 1) {
        return this.totalCount
      } else return this.survivorCount
    } else {
      const gamePool = this.round && this.round['pools'];
      const pool = gamePool.find(p => p.currencyId === this.currencyId)
      if(!pool) return 0;
      if(pot) {
        if(pool.rakedPot < pool.minPot) return pool.minPot
        else return pool.rakedPot
      }
      return pool.totalTickets;
    }
  }

  isRebuy() {
    return this.round?.roundNumber > 1;
  }
}
