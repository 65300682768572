import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
    ElementRef,
  } from '@angular/core';
  
  @Directive({
    selector: '[outsideClick]',
  })
  export class OutsideClickDirective {
    @Output()
    outsideClick: EventEmitter<MouseEvent> = new EventEmitter();
  
    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
      if(!targetElement){
        return;
      }
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.outsideClick.emit();
      }
    }
  
    constructor(private elementRef: ElementRef) {}
  }