import { Injectable } from '@angular/core';
import { ConfigConstants } from 'config-constants';
import { environment } from 'environments/environment';
import mixpanel from 'mixpanel-browser';
import { BehaviorSubject } from 'rxjs';
declare var gtag;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
    private utmStore = new BehaviorSubject(null);
    utmDataObserver = this.utmStore.asObservable();

    hasMixpanel = {
      'app': false,
      'talksport': false,
      'dreamteam': false,
      'planetsport': false,
      'fitzdares': false,
      'greatgoals': false,
      'rockthegoat': false,
      'attheraces': false,
      'bolavip': false,
      'whoscored': false,
      'racingpost': false,
      'oddschecker': false,
    }

    utmData: any = {
      source: 'default',
      medium: 'default',
      campaign: 'default',
    };

    constructor() {
      this.utmDataObserver.subscribe(res => {
        if(res) this.utmData = res;
      })
      this.hasMixpanel['app'] = ConfigConstants.MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['planetsport'] = ConfigConstants.PLANETSPORT_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['talksport'] = ConfigConstants.TALKSPORT_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['fitzdares'] = ConfigConstants.FITZDARES_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['dreamteam'] = ConfigConstants.DREAMTEAM_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['greatgoals'] = ConfigConstants.GREATGOALS_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['attheraces'] = ConfigConstants.ATTHERACES_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['whoscored'] = ConfigConstants.WHOSCORED_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['racingpost'] = ConfigConstants.RACINGPOST_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['oddschecker'] = ConfigConstants.ODDSCHECKER_MIXPANEL_TOKEN.length>0;
      this.hasMixpanel['bolavip'] = ConfigConstants.BOLAVIP_MIXPANEL_TOKEN.length>0;
    }

    loadMixpanel() {

      document.cookie.split(';').forEach(c => {
        c = c.trim()
        // Remove mixpanel cookies now we're favouring localStorage
        if (c.trim().startsWith('mp_')) {
          const name = c.split('=')[0];
          document.cookie = name + '=;domain=.networkgaming.co.uk;path=/;expires=' + new Date(0).toUTCString();
        }
      })

      if(this.hasMixpanel['app']) mixpanel.init(ConfigConstants.MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' });
      if(this.hasMixpanel['planetsport']) mixpanel.init(ConfigConstants.PLANETSPORT_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'planetsport');
      if(this.hasMixpanel['talksport']) mixpanel.init(ConfigConstants.TALKSPORT_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'talksport');
      if(this.hasMixpanel['fitzdares']) mixpanel.init(ConfigConstants.FITZDARES_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'fitzdares');
      if(this.hasMixpanel['dreamteam']) mixpanel.init(ConfigConstants.DREAMTEAM_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'dreamteam');
      if(this.hasMixpanel['greatgoals']) mixpanel.init(ConfigConstants.GREATGOALS_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'greatgoals');
      if(this.hasMixpanel['attheraces']) mixpanel.init(ConfigConstants.ATTHERACES_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'attheraces');
      if(this.hasMixpanel['whoscored']) mixpanel.init(ConfigConstants.WHOSCORED_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'whoscored');
      if(this.hasMixpanel['racingpost']) mixpanel.init(ConfigConstants.RACINGPOST_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'racingpost');
      if(this.hasMixpanel['oddschecker']) mixpanel.init(ConfigConstants.ODDSCHECKER_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'oddschecker');
      if(this.hasMixpanel['bolavip']) mixpanel.init(ConfigConstants.BOLAVIP_MIXPANEL_TOKEN, { debug: !ConfigConstants.IS_PROD, persistence: 'localStorage' }, 'bolavip');
    }

    fetchInstance(affiliate) {
      const instances =  {
        'talksport' : mixpanel.talksport,
        'planetsport': mixpanel.planetsport,
        'dreamteam': mixpanel.dreamteam,
        'fitzdares': mixpanel.fitzdares,
        'greatgoals': mixpanel.greatgoals,
        'rockthegoat': mixpanel.rockthegoat,
        'attheraces': mixpanel.attheraces,
        'bolavip': mixpanel.bolavip,
        'whoscored': mixpanel.whoscored,
        'racingpost': mixpanel.racingpost,
        'oddschecker': mixpanel.oddschecker,
      }
      return instances[affiliate];
    }

    loadGAScript() {
      const envType = environment.environment_name;
      const gaId = environment.googleAnalyticId || 'G-XXX';
      let gaSrc = '';
      if (envType === 'dev') {
        gaSrc = './assets/scripts/google-analytics-dev.js';
      }
      if (envType === 'stage') {
        gaSrc = './assets/scripts/google-analytics-stage.js';
      }
      if (envType === 'production') {
        gaSrc = './assets/scripts/google-analytics-prod.js';
      } else {
        gaSrc = './assets/scripts/google-analytics-dev.js';
      }
  
      if (!envType || !gaId) {
        return;
      }
  
      const node1 = document.createElement('script');
      node1.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaId;
      node1.type = 'text/javascript';
      node1.async = true;
      node1.charset = 'utf-8';
  
      const node2 = document.createElement('script');
      node2.src = gaSrc;
      node2.type = 'text/javascript';
      node2.async = true;
      node2.charset = 'utf-8';
  
      document.getElementsByTagName('head')[0].appendChild(node1);
      document.getElementsByTagName('head')[0].appendChild(node2);
    }
  
    updateUtmStore(utmData) {
      let { source, medium, campaign } = utmData
      if(!source) source = 'default'
      if(!medium) medium = 'default'
      if(!campaign) campaign = 'default'
      this.utmStore.next({ source, medium, campaign })
    }

    saveUtmToLocalStorage() {
      console.log('saving utm')
      localStorage.setItem('UTM_DATA', JSON.stringify(this.utmData))
    }

    retrieveUtms() {
      return {
       ...this.utmData
      }
    }

    pushGtmLoginEvent() {
        const { source, medium, campaign } = this.retrieveUtms();
        const affiliate = localStorage.getItem("AFF_NAME")
        
        console.log('LoginComplete',source,medium,campaign)

        gtag('event', 'LoginComplete', {
          'event_category': 'Onboarding',
          'event_label': 'LoginComplete',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })

        if(this.hasMixpanel['app']) {
          mixpanel.track('LoginComplete', {
            'event_category': 'Onboarding',
            'event_label': 'LoginComplete',
            'affiliate': affiliate,
            'source': source,
            'medium': medium,
            'campaign': campaign,
          })
        } 

        if(this.hasMixpanel[affiliate]) {
          const mixpInst = this.fetchInstance(affiliate)
          mixpInst.track('LoginComplete', {
            'event_category': 'Onboarding',
            'event_label': 'LoginComplete',
            'affiliate': affiliate,
            'source': source,
            'medium': medium,
            'campaign': campaign,
          })
        }
  
    }
  
    pushGtmLoginLanded() {
      const { source, medium, campaign } = this.retrieveUtms();
      const affiliate = localStorage.getItem("AFF_NAME")

      console.log('LoginLanded', source,medium,campaign)

      gtag('event', 'LoginLanded', {
        'event_category': 'Onboarding',
        'event_label': 'LoginLanded',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('LoginLanded', {
          'event_category': 'Onboarding',
          'event_label': 'LoginLanded',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('LoginLanded', {
          'event_category': 'Onboarding',
          'event_label': 'LoginLanded',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    pushGtmAnonPopupEvent(eventName) {
      const { source, medium, campaign } = this.retrieveUtms();
      const affiliate = localStorage.getItem("AFF_NAME")

      gtag('event', eventName, {
        'event_category': 'Onboarding',
        'event_label': eventName,
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventName, {
          'event_category': 'Onboarding',
          'event_label': eventName,
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      } 

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventName, {
          'event_category': 'Onboarding',
          'event_label': eventName,
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    

    pushSignupStartedEvent() {
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();

      console.log('RegistrationStarted', source,medium,campaign)

      gtag('event', 'RegistrationStarted', {
        'event_category': 'Onboarding',
        'event_label': 'RegistrationStarted',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('RegistrationStarted', {
          'event_category': 'Onboarding',
          'event_label': 'RegistrationStarted',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('RegistrationStarted', {
          'event_category': 'Onboarding',
          'event_label': 'RegistrationStarted',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    pushSignupCompleteEvent() {
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();
  
      console.log('RegistrationComplete', source,medium,campaign)

      gtag('event', 'RegistrationComplete', {
        'event_category': 'Onboarding',
        'event_label': 'RegistrationComplete',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('RegistrationComplete', {
          'event_category': 'Onboarding',
          'event_label': 'RegistrationComplete',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('RegistrationComplete', {
          'event_category': 'Onboarding',
          'event_label': 'RegistrationComplete',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

    }

    pushSignupLanded() {
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();

      console.log('RegistrationLanded', source,medium,campaign)

      gtag('event', 'RegistrationLanded', {
        'event_category': 'Onboarding',
        'event_label': 'RegistrationLanded',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('RegistrationLanded', {
          'event_category': 'Onboarding',
          'event_label': 'RegistrationLanded',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('RegistrationLanded', {
          'event_category': 'Onboarding',
          'event_label': 'RegistrationLanded',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    pushGtmResetPasswordEvent() {
      const { source, medium, campaign } = this.retrieveUtms();
      const affiliate = localStorage.getItem("AFF_NAME")

      console.log('ResetPassword', source,medium,campaign)

      gtag('event', 'ResetPassword', {
        'event_category': 'Onboarding',
        'event_label': 'ResetPassword',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('ResetPassword', {
          'event_category': 'Onboarding',
          'event_label': 'ResetPassword',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('ResetPassword', {
          'event_category': 'Onboarding',
          'event_label': 'ResetPassword',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    pushGtmKycAttemptedEvent() {
      const { source, medium, campaign } = this.retrieveUtms();
      const affiliate = localStorage.getItem("AFF_NAME")

      console.log('KycAttempted', source,medium,campaign)

      gtag('event', 'KycAttempted', {
        'event_category': 'Onboarding',
        'event_label': 'KycAttempted',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('KycAttempted', {
          'event_category': 'Onboarding',
          'event_label': 'KycAttempted',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('KycAttempted', {
          'event_category': 'Onboarding',
          'event_label': 'KycAttempted',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    pushGtmKycFailedEvent() {
      const { source, medium, campaign } = this.retrieveUtms();
      const affiliate = localStorage.getItem("AFF_NAME")

      console.log('KycFailed', source,medium,campaign)

      gtag('event', 'KycFailed', {
        'event_category': 'Onboarding',
        'event_label': 'KycFailed',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('KycFailed', {
          'event_category': 'Onboarding',
          'event_label': 'KycFailed',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('KycFailed', {
          'event_category': 'Onboarding',
          'event_label': 'KycFailed',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
        })
      }
    }

    pushGtmCheckoutEvent(data) {
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();

      console.log('CheckoutComplete', source,medium,campaign)

      gtag('event', 'CheckoutComplete', {
        'event_category': 'Purchase',
        'event_label': 'CheckoutComplete',
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
        'amount': data.checkout.amount,
        'gameId': data.checkout.gameId,
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track('CheckoutComplete', {
          'event_category': 'Purchase',
          'event_label': 'CheckoutComplete',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
          'amount': data.checkout.amount,
          'gameId': data.checkout.gameId,
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track('CheckoutComplete', {
          'event_category': 'Purchase',
          'event_label': 'CheckoutComplete',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
          'amount': data.checkout.amount,
          'gameId': data.checkout.gameId,
        })
      }
    }

    pushGTMOfferClicks(offer) {
      const { ctaUrl, code } = offer
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();
      let eventLabel =  'DefaultOffer';
      if(offer.code === 'DEFAULT_CHECKOUT_350_250') eventLabel = 'CheckoutOffer'
      else if(offer.code === 'SRVR_PICKER_350_50') eventLabel = 'SurvivorOffer'

      gtag('event', eventLabel, {
        'event_category': 'Offers',
        'event_label': eventLabel,
        'affiliate': affiliate,
        'source': source,
        'medium': medium,
        'campaign': campaign,
        'offer_target': ctaUrl,
        'code': code
      })

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventLabel, {
          'event_category': 'Offers',
          'event_label': eventLabel,
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
          'offer_target': ctaUrl,
          'code': code
        })
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventLabel, {
          'event_category': 'Offers',
          'event_label': 'CheckoutOffer',
          'affiliate': affiliate,
          'source': source,
          'medium': medium,
          'campaign': campaign,
          'offer_target': ctaUrl,
          'code': code
        })
      }
    }

    registerMixpanelUser(user) {
      const userId = user.id.toString();
      const affiliate = localStorage.getItem("AFF_NAME")
      if(this.hasMixpanel['app']) {
        mixpanel.identify(userId)
        mixpanel.people.set({
          'Affiliate': affiliate,
          '$name': user.displayName,
          '$email': user.email,
          'Deposit Consent': user.depositConsent,
          'Date of Birth': user.dob,
        })
        mixpanel.register({
          'Affiliate': affiliate,
        })
      }
     
      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.identify(userId)
        mixpInst.people.set({
        'Affiliate': affiliate,
        '$name': user.displayName,
        '$email': user.email,
        'Deposit Consent': user.depositConsent,
        'Date of Birth': user.dob,
      })
        mixpInst.register({
        'Affiliate': affiliate,
      }) 
      }
    }

    trackDepositCharges(amount) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.people.track_charge(amount, {
          '$time': new Date()
        });
      }
      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
          mixpInst.people.track_charge(amount, {
            '$time': new Date()
          });
      }
    }

    trackGAInteraction(eventName, eventCategory) {
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();

      gtag('event', eventName, {
        'event_category': eventCategory,
        'event_label': eventName,
        affiliate,
        source,
        medium,
        campaign
      })
    }

    trackInteractions(eventName, gameId?, gameType?) {
      const affiliate = localStorage.getItem("AFF_NAME")
      const { source, medium, campaign } = this.retrieveUtms();

      const track = {
        affiliate
      };
      if (gameId) {
        track['Game ID'] = gameId;
      }
      if (gameType) {
        track['Game Type'] = gameType;
      }
      if (source !== 'default') {
        track['source'] = source;
        track['medium'] = medium;
        track['campaign'] = campaign;
      }

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventName, track)
      }
     
      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventName, track)
      }
    }

    sgsLeaderboardFeedTimeEvent(eventName) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.time_event(eventName)
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.time_event(eventName)
      }
    }

    sgsleaderboardFeedTrackTimeEvent(eventName, gameId, gameType) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventName, {
          'Game ID': gameId,
          'Game Type': gameType,
          'Affiliate': affiliate
        })
      }
     
      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventName, {
          'Game ID': gameId,
          'Game Type': gameType,
          'Affiliate': affiliate
        })
      }
    }
    

    trackPageVisitEvents(eventName) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventName)
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventName)
      }
    
    }

    leaderboardTimeEvent(eventName) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.time_event(eventName)
      }

      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.time_event(eventName)
      }
    }

    leaderboardTrackTimeEvent(eventName, gameId, gameType) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventName, {
          'Game ID': gameId,
          'Game Type': gameType
        })
      }
     
      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventName, {
          'Game ID': gameId,
          'Game Type': gameType
        })
      }
    }

    trackTicketPurchaseRetention(eventName, gameId, gameType) {
      const affiliate = localStorage.getItem("AFF_NAME")

      if(this.hasMixpanel['app']) {
        mixpanel.track(eventName, {
          'Game ID': gameId,
          'Game Type': gameType
        })
      }
    
      if(this.hasMixpanel[affiliate]) {
        const mixpInst = this.fetchInstance(affiliate)
        mixpInst.track(eventName, {
          'Game ID': gameId,
          'Game Type': gameType
        })
      }
   
    }

  gameTypeIdToString(id: number): string {
    switch (id) {
      case 1: 
        return 'PICK_6';
      case 2: 
      case 7: 
        return 'SURVIVOR';
      case 3: 
        return 'SHORT';
      case 4: 
      case 8:
        return 'HORSE_RACING';
      case 5:
      case 9:
        return 'SGS';
      case 6: 
        return 'WCS';
      default:
        throw 'invalid game type';
        
    }
  }

  
  
}
