import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AffiliateManager } from 'projects/fantasy-shared/src/services/affiliatemanager.service'
import { ConfigConstants } from 'config-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {

  year: number;
  version: string = '';
  @Input('container') container: boolean;
  @Input('isLanding') isLanding: boolean;
  affliateName: any;
  privacyPolicyLink : string = '';
  cookiePolicyLink : string = '';
  tncLink : string = '';
  affStoreSub: Subscription;
  partnerPage: boolean = false;

  constructor(
    private affManager: AffiliateManager,
    private ActivatedRoute: ActivatedRoute,
  ) {
    this.ActivatedRoute.queryParams.subscribe(res => {
      this.partnerPage = window.location.pathname.includes("partner")
    });
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.version = ConfigConstants.APP_VERSION;
    this.affStoreSub = this.affManager.affiliateObserver.subscribe(res => {
    this.updateLinks();
    });
    // this.updateLinks();
  }

  ngOnChanges() {
    this.affliateName = this.affManager.getAffName();
    this.updateLinks();
  }

  updateLinks() {
    this.affliateName = this.affManager.getAffName();
    this.privacyPolicyLink = this.affManager.getPrivacyPolicyLink();
    this.cookiePolicyLink = this.affManager.getCookieLink();
    this.tncLink = this.affManager.getTnCLink();
  }

  gambleAwareNav(){
    console.log("click")
  }

  getMinHeight(){
    if(!this.partnerPage) return 'unset'
    else {
      let doc: Element = document.getElementsByClassName("signup_wrapper")[0]
      if(!doc){
        return "calc(100vh - 745px)"
      } else {
        // console.log("calc(100vh - " + doc.clientHeight + "px)")
        // return "calc(100vh - 745px)"
        return "calc(100vh - " + doc.clientHeight + "px)"
      }
    }
  }

}
