import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigConstants } from 'config-constants';
import { UtilsService } from 'projects/fantasy-shared/src/services';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OperatorApiService {

  constructor(private http: HttpClient, private utilsService: UtilsService, private router: Router) { }

  getOperatorProfile(token) {
    const sessionId = localStorage.getItem('sessionToken')
    const affId = localStorage.getItem("AFF_ID")
    const sesionString = sessionId ? `&sessionId=${sessionId}` : ''
    const url = ConfigConstants.API_URL_V3 + 'operators/profile?token=' + token + '&affiliateId=' + affId + sesionString;
    return this.http.get(url)
  }

}

