import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ConfigConstants } from 'config-constants';


@Injectable()
export class CsrfInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('CSRF_TOKEN') !== null) {
      request = this.addToken(request, localStorage.getItem('CSRF_TOKEN'));
    }

    if (request.url.startsWith(ConfigConstants.API_URL_V2.replace('/v2/', ''))) {
      request = request.clone({withCredentials: true});
    }

    return next.handle(request).pipe(tap((httpEvent: HttpEvent<any>) => {
      // Skip request
      if(httpEvent.type === 0){
        return;
      }           

      if (httpEvent instanceof HttpResponse) {
        if (httpEvent.headers.get('X-Csrf-Token')) {
          localStorage.setItem('CSRF_TOKEN', httpEvent.headers.get('X-Csrf-Token'))
        }
      }
    }));

  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'X-CSRF-Token': `${token}`,
      }
    });
  }
}
