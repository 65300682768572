import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { ConfigConstants } from 'config-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AnalyticsService, ThemeService } from './index';
import { AffiliateManager } from './affiliatemanager.service'
import { LocalStorageService } from './local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'projects/player/src/api-service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import moment from 'moment';
import { OperatorApiService } from 'projects/operator-player/src/api-service/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;
  private returnUrlonLogin: string =  '';
  private affId: number = 0;
  constructor(
    private http: HttpClient, 
    private router: Router,
    private apiService: ApiService,
    private themeService: ThemeService,
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private affManager: AffiliateManager,
    private activatedRoute : ActivatedRoute,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private operatorApiService: OperatorApiService,
    private location: Location,
  ) { 
    this.activatedRoute.queryParams.subscribe(res => {
      this.returnUrlonLogin = res['returnUrl']
      // console.log(this.returnUrlonLogin)
    })
  }

  login(user: { email: string, password: string }) {
    const sessionId = localStorage.getItem('sessionToken')
    const affiliateId = parseInt(localStorage.getItem('AFF_ID'))
    let userPayload: any = { ...user }
    if(sessionId !== '') {
      userPayload = { ...user, sessionId, affiliateId }
    }
    const helper = new JwtHelperService();
    return new Promise((resolve, reject) => {
      this.http.post(`${ConfigConstants.API_URL_V2}players/login`, userPayload).subscribe((res: any) => {
        if(res.data && res.errorCode === 0) {
            this.doLoginUser(res);
            const role_id =  localStorage.getItem('ROLE_ID');
            const decodedToken = helper.decodeToken(localStorage.getItem('JWT_TOKEN'));
            const getAffId = decodedToken['user']['affiliateId'];
          
            const preferredLanguage = res.data.playerDetails.language;
            preferredLanguage ? this.translateService.use(preferredLanguage) : this.translateService.use('en');
            
            // const journeyComplete = decodedToken['user']['journeyComplete'];
            // const journeyVersion = decodedToken['user']['journeyVersion'];
            const getClientName = this.affManager.getAffNameByAffId(getAffId)
  
            if(role_id !== '1') {
              if(getClientName === 'fitzdares' && !ConfigConstants.APP_AFFILIATES.includes('FTZ')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'talksport' && !ConfigConstants.APP_AFFILIATES.includes('TS')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'dreamteam' && !ConfigConstants.APP_AFFILIATES.includes('DT')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'rockthegoat' && !ConfigConstants.APP_AFFILIATES.includes('RTG')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'racingpost' && !ConfigConstants.APP_AFFILIATES.includes('RPOST')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'oddschecker' && !ConfigConstants.APP_AFFILIATES.includes('OC')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'bolavip' && !ConfigConstants.APP_AFFILIATES.includes('BVIP')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'whoscored' && !ConfigConstants.APP_AFFILIATES.includes('WS')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'planetsport' && !ConfigConstants.APP_AFFILIATES.includes('PS')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'greatgoals' && !ConfigConstants.APP_AFFILIATES.includes('GG')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'attheraces' && !ConfigConstants.APP_AFFILIATES.includes('ATR')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'nationalworld' && !ConfigConstants.APP_AFFILIATES.includes('NW')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'alpha' && !ConfigConstants.APP_AFFILIATES.includes('AL')) {
                this.doLogoutUser();
                resolve(false)
                return;
              }
            } else if (role_id === '1') {
              if(!ConfigConstants.PLATFORM_APPS.includes('admin')) {
                this.doLogoutUser();
                resolve(true)
                return;
              }
            }

            // localStorage.setItem('AFF_ID', getAffId);
            // localStorage.setItem('AFF_NAME', getClientName);
            this.affManager.setAffiliateToStorage(getClientName)
            // localStorage.setItem('JOURNEY_COMPLETE', journeyComplete.toString())
            // localStorage.setItem('JOURNEY_VERSION', journeyVersion.toString())
            this.themeService.setActiveThemeByName(getClientName);
            if (role_id === '1') {
              this.router.navigate(['/games']);
            } else if (role_id === '4') {
              this.router.navigate(['/partner-reporting'])
            } else {
              this.analyticsService.pushGtmLoginEvent();
              this.apiService.getWallets();

              if(this.returnUrlonLogin) this.router.navigateByUrl(this.returnUrlonLogin)
              else this.router.navigate(['/']);
            }
        } else if (res.data.accountStatus === 'INACTIVE') {
          resolve('INACTIVE ACCOUNT')
          // this.processing = false;
        } else if (res.data === 'GBG Failed') {
          let rerouteKey = localStorage.getItem('AFF_NAME') + '?gbgFailed=true';
          this.router.navigateByUrl(rerouteKey) 
          resolve('GBG FAILED')
          // this.processing = false;
        } else if (res.data === 'GamSTOP Failed' || res.data === 'GamSTOP Unauthorized') {
          let rerouteKey = localStorage.getItem('AFF_NAME') + '?gamstopFailed=true';
          this.router.navigateByUrl(rerouteKey) 
          resolve('GAMSTOP FAILED')
          // this.processing = false;
        } else {
          resolve('ERROR')
        }
      }, (err) => {
        if(err.data){
          if (err.data.accountStatus && err.data.accountStatus === 'INACTIVE') {
            resolve('INACTIVE ACCOUNT')
          } else if (err.data === 'GBG Failed') {
            let rerouteKey = localStorage.getItem('AFF_NAME') + '?gbgFailed=true';
            this.router.navigateByUrl(rerouteKey) 
            resolve('GBG FAILED')
          } else if (err.data === 'GamSTOP Failed' || err.data === 'GamSTOP Unauthorized') {
            let rerouteKey = localStorage.getItem('AFF_NAME') + '?gamstopFailed=true';
            this.router.navigateByUrl(rerouteKey) 
            resolve('GAMSTOP FAILED')
          } else {
            resolve(err.data)
          }
        }else {
          resolve(err)
        }
      });
    })
   
  }

  csrf() {
    return new Promise((resolve, reject) => {
      this.http.get(`${ConfigConstants.API_URL_V2}csrf`).subscribe((res: any) => {
        resolve(true)
      })
    })
  }

  otpLogin(otpToken) {
    const helper = new JwtHelperService();
    return new Promise((resolve, reject) => {
      localStorage.setItem("OTP_LOGIN_INPROGRESS", "true")
      this.http.post(`${ConfigConstants.API_URL_V2}players/login`, { otp: otpToken }).subscribe((res: any) => {
        localStorage.setItem("OTP_LOGIN_INPROGRESS", "false")
        if(res.data && res.errorCode === 0) {
            this.doLoginUser(res);
            const role_id =  localStorage.getItem('ROLE_ID');
            const decodedToken = helper.decodeToken(localStorage.getItem('JWT_TOKEN'));
            const getAffId = decodedToken['user']['affiliateId'];
            const journeyComplete = decodedToken['user']['journeyComplete'];
            const getClientName = this.affManager.getAffNameByAffId(getAffId)
  
            if(role_id !== '1') {
              if(getClientName === 'fitzdares' && !ConfigConstants.APP_AFFILIATES.includes('FTZ')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'talksport' && !ConfigConstants.APP_AFFILIATES.includes('TS')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'dreamteam' && !ConfigConstants.APP_AFFILIATES.includes('DT')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'rockthegoat' && !ConfigConstants.APP_AFFILIATES.includes('RTG')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'racingpost' && !ConfigConstants.APP_AFFILIATES.includes('RPOST')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'oddschecker' && !ConfigConstants.APP_AFFILIATES.includes('OC')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'bolavip' && !ConfigConstants.APP_AFFILIATES.includes('BVIP')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'whoscored' && !ConfigConstants.APP_AFFILIATES.includes('WS')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'planetsport' && !ConfigConstants.APP_AFFILIATES.includes('PS')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'greatgoals' && !ConfigConstants.APP_AFFILIATES.includes('GG')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'attheraces' && !ConfigConstants.APP_AFFILIATES.includes('ATR')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'nationalworld' && !ConfigConstants.APP_AFFILIATES.includes('NW')) {
                this.doLogoutUser();
                resolve(false)
                return;
              } else if(getClientName === 'alpha' && !ConfigConstants.APP_AFFILIATES.includes('AL')) {
                this.doLogoutUser();
                resolve(false)
                return;
              }
            } else if (role_id === '1') {
              if(!ConfigConstants.PLATFORM_APPS.includes('admin')) {
                this.doLogoutUser();
                resolve(true)
                return;
              }
            }

            // localStorage.setItem('AFF_ID', getAffId);
            // localStorage.setItem('AFF_NAME', getClientName);
            this.affManager.setAffiliateToStorage(getClientName)

            // localStorage.setItem('JOURNEY_COMPLETE', journeyComplete.toString())
            this.themeService.setActiveThemeByName(getClientName);
            // console.log(this.returnUrlonLogin)
            if (role_id === '1') {
              this.router.navigate(['/games']);
            } else {
              this.analyticsService.pushGtmLoginEvent();
              this.apiService.getWallets();

              if(this.returnUrlonLogin) this.router.navigateByUrl(this.returnUrlonLogin)
              else {
                if(this.activatedRoute['_routerState']?.snapshot?.url?.split('/')?.length >= 2 && !this.activatedRoute['_routerState']?.snapshot?.url?.startsWith('/partner/')) window.location.reload();
                else this.router.navigate(['/'])
              } 
            }
        } else if (res.data.accountStatus === 'INACTIVE') {
          resolve('INACTIVE ACCOUNT')
          // this.processing = false;
        } else if (res.data === 'GBG Failed') {
          let rerouteKey = '/partner/' + localStorage.getItem('AFF_NAME') + '?gbgFailed=true';
          this.router.navigateByUrl(rerouteKey) 
          resolve('GBG FAILED')
          // this.processing = false;
        } else if (res.data === 'GamSTOP Failed' || res.data === 'GamSTOP Unauthorized') {
          let rerouteKey = '/partner/' + localStorage.getItem('AFF_NAME') + '?gamstopFailed=true';
          this.router.navigateByUrl(rerouteKey) 
          resolve('GAMSTOP FAILED')
          // this.processing = false;
        } else if (res.data === 'failed to create history') {
          resolve('ERROR')
          this.toastrService.error("Otp Login failed")
        } else {
          resolve('ERROR')
  
          // this.resetErrors();
          // this.processing = false;
        }
      }, (err) => {
        localStorage.setItem("OTP_LOGIN_INPROGRESS", "false")
        let errMsg =";"
        if(err['error'].data === 'LoginPlayer - handler - invalid credentials - expired OTP'){
          errMsg = "OTP_EXPIRED";
        } else if(err['error'].data === 'LoginPlayer - handler - invalid credentials - invalid OTP'){
          errMsg = "OTP_EXPIRED";
          // errMsg = "Invalid OTP";
        } else {
          errMsg = "OTP Login failed";
        }
        let rerouteKey = '/partner/' + localStorage.getItem('AFF_NAME');
        if(errMsg === "OTP_EXPIRED") this.resendOTP(otpToken)
        this.router.navigateByUrl(rerouteKey + '?otpError=true&otpErrorMessage=' + errMsg) 
        reject('error')
        // this.resetErrors()
        // this.processing = false;
      });
    });
  }

  resendOTP(otpToken){
    let str = '';
    let affiliate = ''
    let affId = 0;
    let strArr = window.location.href.split("?").join('').split("&").find(a => a.includes("affid"));
    if (affId) affiliate = this.affManager.getAffNameByAffId(Number(strArr.split("=")[1]));
    else affiliate = this.affManager.getAffName();
    if(!this.returnUrlonLogin) str = window.location.origin+'/partner/'+affiliate;
    else str = this.returnUrlonLogin.split(`otp=${otpToken}`).join("");
    if (!str.startsWith('http')) {
      // If return url doesn't contain the domain, prepend the domain
      str = window.location.origin + str;
    }
    const data = {
      "otp": otpToken,
      "forward_url": str
    }
    this.http.post(`${ConfigConstants.API_URL_V2}otp/refresh`, { ...data }).subscribe((res: any) => {
      // console.log("OTP resent:",res)
    }, err => {
      console.log(err['error'].data)
    })
  }

  isLoggedIn() {
    // !!undefined is false but it is coming in as a string;
    let jwtToken = this.getJwtToken();
    if(jwtToken === 'undefined' || jwtToken === 'null') return false;
    return !!this.getJwtToken();
  }

  refreshToken() {
    // console.log('in refresh token for testing access token');
    return this.http.post<any>(`${ConfigConstants.API_URL_V2}players/newaccesstoken`, {
      'accessToken': this.getJwtToken(),
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens) => {
      this.storeJwtToken(tokens['data']['newAccessToken']);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public doLoginUser(tokens) {
    this.storeTokens(tokens);
  }

  public doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  } 

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens) {
    this.localStorageService.storeTokensAtLogin(tokens['data'])
  }

  public removeTokens() {
    this.localStorageService.removeTokensAtLogout();
    let routeName = '';
    let affName = localStorage.getItem('AFF_NAME')
    if(affName === 'greatgoals') routeName = '101greatgoals'
    else routeName = affName
    window.location.href = '/partner/' + routeName;
  }

  getParameterByName(name, url) {
    const match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  handleAppRouterEventCallback(item, queryParams) {
    let otp = queryParams['otp']
    let affID = queryParams['affid']
    let affName = queryParams['affName']
    if(affID) {
      this.affManager.setAffiliateToStorage(this.affManager.getAffNameByAffId(Number(affID)))
      // localStorage.setItem('AFF_ID', affID);
      // localStorage.setItem('AFF_NAME', this.affManager.getAffNameByAffId(Number(affID)));
    }
    if(affName && affName.length>0) {
      const isLoggedIn = localStorage.getItem('JWT_TOKEN')
      if(!isLoggedIn || isLoggedIn.length===0) this.affManager.setAffiliateToStorage(affName)
    }
    const returnUrl = queryParams['returnUrl']
    if(returnUrl) {
      otp = this.getParameterByName('otp', returnUrl)
      this.returnUrlonLogin = queryParams['returnUrl'].split("?otp")[0]
    }
    const displayName = localStorage.getItem('DISPLAY_NAME');
    const roleId =  Number(localStorage.getItem('ROLE_ID'));
    const jwttoken = localStorage.getItem('JWT_TOKEN');

    this.affId =  Number(localStorage.getItem('AFF_ID'));
    if (this.affId === 1) {
      this.themeService.setFitzdaresTheme();
    } else if (this.affId === 2) {
      this.themeService.setTalksportTheme();
    } else if (this.affId === 3) {
      this.themeService.setDreamteamTheme();
    } else if (this.affId === 4) {
      this.themeService.setRTGTheme();
    } else if (this.affId === 5) {
      this.themeService.setRacingpostTheme();
    } else if (this.affId === 7) {
      this.themeService.setOddscheckerTheme();
    } else if (this.affId === 8) {
      this.themeService.setBolavipTheme();
    }  else if (this.affId === 9) {
      this.themeService.setWhoScoredTheme();
    }  else if (this.affId === 10) {
      this.themeService.setPlanetSportTheme();
    }  else if (this.affId === 11) {
      this.themeService.setGreatGoalsTheme();
    } else if (this.affId === 12) {
      this.themeService.setAtTheRacesTheme();
    } else if (this.affId === 13) {
      this.themeService.setNationalWorldTheme();
    } else if (this.affId === 15) {
      this.themeService.setFitzdaresSportsBookTheme();
    } else if (this.affId === 16) {
      this.themeService.setAlphaTheme();
    }

    const otpLoginInprogress = localStorage.getItem("OTP_LOGIN_INPROGRESS") === "true"


    if (jwttoken !== 'undefined' && jwttoken !== null && !this.isTokenExpired()) {

      // If the jwt hasn't expired but a link routes a user to the sign-in page, redirect to the lobby
      if (this.activatedRoute['_routerState']?.snapshot?.url?.startsWith('/partner/')) {
        this.router.navigate(['/'])
      }
     
      // console.log('new block reached')
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(jwttoken);
      const getAffId = decodedToken['user']['affiliateId'];
      const getClientName = (function() {
        if (getAffId === 1) { return 'fitzdares'; } 
        else if (getAffId === 2) { return 'talksport'; } 
        else if (getAffId === 3) { return 'dreamteam'; }  
        else if (getAffId === 4) { return 'rockthegoat'; }
        else if (getAffId === 5) { return 'racingpost'; }
        else if (getAffId === 7) { return 'oddschecker'; }
        else if (getAffId === 8) { return 'bolavip'; }
        else if (getAffId === 9) { return 'whoscored'; }
        else if (getAffId === 10) { return 'planetsport'; }
        else if (getAffId === 11) { return 'greatgoals'; }
        else if (getAffId === 12) { return 'attheraces'; }
        else if (getAffId === 13) { return 'nationalworld'; }
        else if (getAffId === 15) { return 'fitzdaressportsbook'; }
        else if (getAffId === 16) { return 'alpha'; }
      })();
      // localStorage.setItem('AFF_ID', getAffId);
      // localStorage.setItem('AFF_NAME', getClientName);
      this.affManager.setAffiliateToStorage(getClientName)
      this.themeService.setActiveThemeByName(getClientName);

       // Redirect player to singup view page, if signup journey is incomplete.
      const isUserJourneyComplete = localStorage.getItem("JOURNEY_COMPLETE") === 'true'
      if (!isUserJourneyComplete && roleId === 3) { /// should be 3?
        switch (this.affId) {
          case 1:
            if(ConfigConstants.APP_AFFILIATES.includes('FTZ'))
              this.router.navigate(['/partner/fitzdares/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 2:
            if(ConfigConstants.APP_AFFILIATES.includes('TS'))
              this.router.navigate(['/partner/talksport/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 3:
            if(ConfigConstants.APP_AFFILIATES.includes('DT'))
              this.router.navigate(['/partner/dreamteam/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 4: 
            if(ConfigConstants.APP_AFFILIATES.includes('RTG'))
              this.router.navigate(['/partner/rockthegoat/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 5: 
            if(ConfigConstants.APP_AFFILIATES.includes('RPOST'))
              this.router.navigate(['/partner/racingpost/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 7: 
            if(ConfigConstants.APP_AFFILIATES.includes('OC'))
              this.router.navigate(['/partner/oddschecker/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 8: 
            if(ConfigConstants.APP_AFFILIATES.includes('OC'))
              this.router.navigate(['/partner/bolavip/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 9: 
            if(ConfigConstants.APP_AFFILIATES.includes('WS'))
              this.router.navigate(['/partner/whoscored/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 10: 
            if(ConfigConstants.APP_AFFILIATES.includes('PS'))
              this.router.navigate(['/partner/planetsport/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 11: 
            if(ConfigConstants.APP_AFFILIATES.includes('GG'))
              this.router.navigate(['/partner/101greatgoals/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 12: 
            if(ConfigConstants.APP_AFFILIATES.includes('ATR'))
              this.router.navigate(['/partner/attheraces/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 13: 
            if(ConfigConstants.APP_AFFILIATES.includes('NW'))
              this.router.navigate(['/partner/nationalworld/register']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          case 16: 
            if(ConfigConstants.APP_AFFILIATES.includes('AL'))
              this.router.navigate(['/partner/alpha']);
            else this.toastrService.error("User does not exist on supported affiliate")
            break;
          default:
            break;
        }
        return;
      }

      if (!displayName && roleId === 2) {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(localStorage.getItem('JWT_TOKEN'));
        if (decodedToken && decodedToken['user']['displayName'] === '') {
          this.router.navigate(['displayname']);
          return;
        }
      }

    } else {
      //otp exists or not
      if(otp) { 
        if(!otpLoginInprogress){
          this.otpLogin(otp)
        }
      } else {

          this.localStorageService.removeTokensAtLogout();
          if (item.url.includes('resetpassword') || item.url.includes('forgot-password') || item.url.includes('contact')) { return }
          if (this.affManager.pushToAffiliate(item.url)) {return} 
          else { 
            if (!this.affId) {
              this.router.navigate(['landing']);
              return;
            }
            if (this.affId && this.router.url.includes('/picker/') && !this.router.url.includes('seen-picker') && (this.router.url.includes('affid') || this.router.url.includes('affName'))) {
              const gameId = this.getParameterByName('gameId', this.router.url);
              this.router.navigate(['splash/' + this.affId], { queryParams: { returnUrl: this.router.url, gameId } });
            }
            return;
          }

      
      }
    }
   
  }

  isTokenExpired(): boolean {
    const refreshTokenExpiry = localStorage.getItem('REFRESH_TOKEN_EXPIRY');
    if (!refreshTokenExpiry || refreshTokenExpiry === 'undefined') {
      return false;
    }
    return moment(new Date()).format() > moment(refreshTokenExpiry).format();
  }
}
