import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastName'
})
export class LastNamePipe implements PipeTransform {
    transform(value: string, [separator]): string {
        const splits = value.split(separator);
        if (splits[0] === 'PLAYER') {
          return value;
        } else {
          if (splits.length > 1) {
            if (splits[1] === '') {
              return splits[0];
            } else {
              return splits.pop();
            }
           } else {
             return value;
           }
        }

      }
}
