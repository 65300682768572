

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RootModalComponent } from './root-modal.component';
import { ModalService } from './root-modal.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  providers: [ModalService],
  declarations: [
    RootModalComponent,
  ],
  exports: [
    RootModalComponent,
  ],
})


export class RootModalModule {
}
