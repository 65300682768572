import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationCancel } from '@angular/router';

import { AnalyticsService, LocalStorageService, ThemeService } from 'projects/fantasy-shared/src/services';
import { AffiliateManager } from 'projects/fantasy-shared/src/services/affiliatemanager.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/fantasy-shared/src/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigConstants } from '../../config-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { v4 as uuidv4 } from 'uuid';
import { AppStore } from 'projects/player/src/app/player-dashboard/appstore.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
  
})
export class AppComponent implements OnInit {
  isLanding: boolean;
  roleId = 0;
  versionOutdated: boolean = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService,
    private affManager: AffiliateManager,
    private authService: AuthService,
    private translateService: TranslateService,
    private appStore: AppStore,
    private http: HttpClient,
  ) {
    this.translateService.setDefaultLang('en');
  }

  ngOnInit() {
    this.analyticsService.loadGAScript();
    this.analyticsService.loadMixpanel();
    
    const sessionToken = localStorage.getItem("sessionToken")
    if(!sessionToken) {
      localStorage.setItem("sessionToken", uuidv4())
    }
    
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        this.activeRoute.queryParams.subscribe(res => {
          this.captureUTMParams(res);
          this.authService.handleAppRouterEventCallback(item, res);
          this.captureAppLoad();
        });

        const baseUrl = item.url.split('?')[0]
        if(['/', '/mygames'].includes(baseUrl)) {
          this.appStore.nonBottomNavFlow.set(true)
        } else {
          this.appStore.nonBottomNavFlow.set(false)
        }
      }
      if (item instanceof NavigationCancel) {
        if(item.url.includes("?otp=")){
          const otp = this.authService.getParameterByName('otp', item.url)
          const returnUrl = item.url
          const affid = this.authService.getParameterByName('affid', item.url)
          const affName = this.authService.getParameterByName('affName', item.url)
          this.authService.handleAppRouterEventCallback(item, {
            otp,
            returnUrl,
            affid,
            affName
          });
        }
        if(!item.url.includes("?otp=")){
          if(!localStorage.getItem("AFF_ID")){
            return this.router.navigate(['/landing'])
          }
        }
      }
    });
    const token = localStorage.getItem('JWT_TOKEN');
    const tokenPayload = new JwtHelperService().decodeToken(token);

    const languages = ConfigConstants.SUPPORTED_LANGUAGES.map(el => el.languageCode);
    this.translateService.addLangs(languages);
    const preferredLanguage = tokenPayload?.user.language;
    const browserLang = this.translateService.getBrowserLang();
    if (preferredLanguage) {
      this.translateService.use(preferredLanguage);
    } else {
      languages.includes(browserLang) ? this.translateService.use(browserLang) : this.translateService.use('en');
    }

    this.startFrontendVersionChecker();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {

    setTimeout(() => {
      this.checkFrontendVersion();
    }, 1000);
  }

  captureUTMParams(res) {
    if((res['utm_source'] + res['utm_medium'] + res['utm_campaign']).length>0) {
      const utmObj = {
        source : res['utm_source'],
        medium : res['utm_medium'],
        campaign : res['utm_campaign'],
      };
      this.analyticsService.updateUtmStore(utmObj)
    }
  }

  captureAppLoad() {
    if(!sessionStorage.getItem('APP_LOADED')) {
      this.analyticsService.trackGAInteraction('AppLoad', 'Retention');
      this.analyticsService.trackInteractions('APP_LOAD');
      sessionStorage.setItem('APP_LOADED', 'true');
    }
  }

  startFrontendVersionChecker() {

    setInterval(() => {
      this.checkFrontendVersion();
    }, 600000);
  }

  checkFrontendVersion() {
    this.http.get(ConfigConstants.APP_CONFIG_S3_BUCKET + '/app-config.json', {headers: {'Cache-Control': 'no-cache'}}).subscribe(res => {
      if (res['frontend_version'] !== ConfigConstants.APP_VERSION) {
        this.versionOutdated = true;
      }
    });
  }

  refreshBrowser() {
    window.location.reload();
  }

}
