import { Component, Input, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigConstants } from "config-constants";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalService } from "projects/fantasy-shared/src/modules/root-modal/root-modal.service";
import { ApiService } from "projects/player/src/api-service/api.service";

@Component({
  selector: 'snakes-grid',
  templateUrl: './snakes-grid.component.html',
  styleUrls: ['./snakes-grid.component.scss']
})
export class SnakesGridComponent {
  @Input() grids: grid[] = [];
  @Input() selectedStake: number;
  @Input() gridMarkerPosition: number;
  @Input() state: string;
  @Input() gameData: any;
  @Input() roundData: any;
  currencyId: number = ConfigConstants.CURRENCY_ID
  
  constructor(
    private modalService: ModalService, 
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridMarkerPosition && changes.gridMarkerPosition.currentValue !== changes.gridMarkerPosition.previousValue) {
      this.moveMarkerOnGrid();
    }
    
    // Check if the grid is not empty and the state is live
    if ((this.gridMarkerPosition === null || this.gridMarkerPosition === undefined)) {
      // Set grid marker display to block
      const marker = document.querySelector('.helmet_marker') as HTMLElement;
      marker.style.display = 'block';
    }
  }

  // Get potential winnings for the user's picks, the potential winning is he getGridPayoutAmounts() of the last pick
  getGridPayoutAmounts(grid) {
    if (!grid || !this.selectedStake) return 0;
    return grid.points * this.selectedStake;
  }


  moveMarkerOnGrid(): void {
    const marker = document.querySelector('.helmet_marker') as HTMLElement;
    marker.style.display = 'block';
    const tmt = setInterval(() => {
      const grid = document.querySelector(`#grid_item_` + this.gridMarkerPosition) as HTMLElement;
      if (marker && grid) {
        clearInterval(tmt)
        // Remove the marker from the previous position and append it to the new position
        marker.remove();
        grid.appendChild(marker);
        grid.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100)
  }  

   closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  gotoNextOpenRound(): void {
    this.spinner.show();
    this.apiService.getGameById(this.gameData.id).subscribe(res => {
      const rounds = res['data']['rounds'];
      const nextOpenRound = rounds.find(round => round.state.toLowerCase() === 'open');
      this.spinner.hide();
      this.closeModal('game-live-modal');
      if (nextOpenRound) {
        this.router.navigate(['/picker/snakes-and-ladders-picker'], { queryParams: { gameId: this.gameData.id, roundId: nextOpenRound.id } });
      }
    }, err => {
      this.spinner.hide();
    })
  }

  /**
    * Get the class for the selected pick, could be pick_0, pick_1, pick_2, pick_3
    * @param grid 
    * @returns 
    */
  getSelectedPickClass(grid): string{
    if (!grid?.picks?.length) return '';
    const pickNumbers = grid.picks.map(pick => pick.pickNumber);
    let pickClass = '';
    pickNumbers.forEach(pickNumber => {
      pickClass += `pick_${pickNumber-1} `;
    });
    return pickClass;
  }
  

}

// Grid interface
export type grid = {
  points: number;
  picks: number;
  stake: number;
  id: number;
  position: number;
  pointBreakdown: any;
  }