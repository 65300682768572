import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AffiliateManager } from 'projects/fantasy-shared/src/services/affiliatemanager.service'
import { ConfigConstants } from 'config-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fsb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FsbFooterComponent implements OnInit, OnChanges {

  year: number;
  version: string = '';
  @Input('container') container: boolean;
  @Input('isLanding') isLanding: boolean;
  affliateName: any;
  privacyPolicyLink : string = '';
  cookiePolicyLink : string = '';
  tncLink : string = '';
  affStoreSub: Subscription;

  constructor(
    private affManager: AffiliateManager,
  ) { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.version = ConfigConstants.APP_VERSION;
    this.affStoreSub = this.affManager.affiliateObserver.subscribe(res => {
    this.updateLinks();
    });
    // this.updateLinks();
  }

  ngOnChanges() {
    this.affliateName = this.affManager.getAffName();
    this.updateLinks();
  }

  updateLinks() {
    this.affliateName = this.affManager.getAffName();
    this.privacyPolicyLink = this.affManager.getPrivacyPolicyLink();
    this.cookiePolicyLink = this.affManager.getCookieLink();
    this.tncLink = this.affManager.getTnCLink();
  }

  gambleAwareNav(){
    console.log("click")
  }

}
